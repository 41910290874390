.c-modal {
    border-radius: 1rem;
    box-shadow: $box-shadow;
    max-width: 56rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.c-modal--ccpa {
    max-width: none;
}

.c-modal__header {
    display: block;
    font-family: $font-family--primary;
    font-size: rem(24);
    margin-bottom: $spacer;
    color: $primary;
    text-align: center; 
}

.c-modal__divider {
    position: relative;
    margin-top: $spacer;
    margin-bottom: $spacer;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    border-bottom: $spacer * 0.062 solid $light;
}

.c-modal__divider-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $primary;
    background-color: $white;
    padding-right: $spacer--s;
    padding-left: $spacer--s;
}

// Form on Sale Rep CTA 
.sales-rep {
    padding: 0;
    border-radius: unset;
    
    .c-modal__content {
        @include media-breakpoint-up(m) {
            display: flex;
        }
        

        .form-modal-intro {
            background-color: $accent;
            padding: 2.5rem;
            color: $white;
            text-align: left;
            position: relative;

            .form-modal__title {
                font-family: $font-family--serif;
                color: $white;
                font-size: 2rem;
                line-height: 1.25;
                text-align: left;
            }

            .form-modal__info {
                background-color: unset;
                padding: 0;
                margin-top: 2rem;
                line-height: 1.62;

            }

            .form-modal__img {
                width: 40%;
                padding-top: 2rem;
                position: absolute;
                bottom: 0;
                @include media-breakpoint-down(m) {
                    display: none;
                }
            }
        }
        .c-form {
            padding: 2.5rem;

            .c-button {
                background-color: $secondary;
                border-color: $secondary;
                color: $black
            }
        }
    }
}