.c-home {
    background-color: color-step($accent, 50);
}

.c-home__selling-payments {
    @include media-breakpoint-up(l) {
        .c-media-block__media {
            width: 60%;
        }
        .c-media-block__content {
            width: 40%;
        }
    }
}

.c-home__cashing-out {
    @include media-breakpoint-up(l) {
        .c-media-block__media {
            width: 40%;
        }
        .c-media-block__content {
            width: 60%;
        }
    }
}

.hero-h2 {
    color: $white;
    margin-bottom: -1.5rem;
}
