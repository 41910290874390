thead td,
thead th {
    background-color: color-step($secondary, 800);
    text-align: center;
}

@each $name, $color in $table-colors {
    $class: 'u-#{$name}';

    tr.#{$class} td,
    tr.#{$class} th,
    td.#{$class},
    th.#{$class}, {
        background-color: $color;
        color: color-yiq($color);
        border-bottom: rem(1) solid $color;
    }
}
