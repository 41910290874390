// Livechat styles
#c-livechat {
    //sass-lint:disable-line no-ids
    position: fixed;
    bottom: $spacer--xs;
    right: $spacer;
    cursor: pointer;
    overflow: hidden;
    visibility: visible;
    z-index: 9999999;
    border: 0;
    padding: 0;
    transition: transform 0.2s ease-in-out;
    transform: translateY(0%);

    &:focus,
    &:active {
        outline: none;
        border: none;
    }

    @include media-breakpoint-down(m) {
        display: none;
    }
}

#c-livechat__loading {
    //sass-lint:disable-line no-ids
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;

    &.u-show {
        display: block;
    }
}

#livechat-compact-container {
    display: none;
} //sass-lint:disable-line no-ids
