.c-blockquote {
    margin-top: $spacer--2xl;
    border-top: rem(1) solid color-step($accent, 100);
    padding: $spacer--2xl 0;
    border-bottom: rem(1) solid color-step($accent, 100);
    margin-bottom: $spacer--2xl;
}

.c-blockquote__content {
    text-align: left;
    position: relative;

    @include media-breakpoint-up(m) {
        padding-left: 4rem;
    }

    &:before {
        @include make-icon();
        content: icon('quote-left');
        color: color-step($accent, 50);
        line-height: 1;
        display: block;
        position: absolute;
        top: -1rem;
        z-index: -1;

        @include media-breakpoint-only(s) {
            font-size: 6rem;
            width: 6rem;
            height: 6rem;
            left: -1rem;
        }

        @include media-breakpoint-up(m) {
            font-size: 3rem;
            width: 3rem;
            height: 3rem;
            left: 0;
        }
    }
}

.c-blockquote__quote {
    @include type-scale(m, 1.5);
    font-family: $font-family--primary;
    font-weight: $font-weight--bold;
    color: $secondary;
}

.c-blockquote__title {
    @include type-scale(base, 1.5);
}

.c-blockquote__subtitle {
    @include type-scale(s, 1.5);
}

.c-blockquote__source {
    color: $gray-800;
}
