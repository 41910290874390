.c-attribution--inline {
    max-width: $content-builder-max-width;
    margin-left: auto;
    margin-right: auto;

    .c-page--two-column & {
        margin-top: 0;
    }

    .c-attribution__item {
        @include media-breakpoint-up(m) {
            display: flex;
            align-items: flex-start;
        }
    }
    
    .c-attribution__image {
        float: left;
        margin-right: $spacer;

        @include media-breakpoint-up(m) {
            float: none;
            flex: 0 0 auto;
        }
        img {
            border-radius: 50%;
        }
    }

    .c-attribution__buttons .c-button {
        margin-right: $spacer--s;
        margin-top: $spacer--xs;
        font-size: $spacer * 0.625;
        letter-spacing: rem(2);
        text-transform: uppercase;

        @include media-breakpoint-up(m) {
            margin-right: $spacer;
        }
    }

    .c-attribution__expert-modal-link {
        margin-top: $spacer--xs;
        line-height: 1;
    }

    .c-attribution__copy ul {
        padding-top: $spacer;

        @include media-breakpoint-up(m) {
            padding-top: 0;
            li {
                display: inline;
                padding-right: $spacer--xs;
                &:before {
                    content: '\2022';
                    margin-right: $spacer--xs;
                }
                &:first-child:before {
                    content: '';
                    margin-right: 0;
                }
            }
        }
    }
}
