//Shared Hero Styles

.c-hero__before {
    @include make-col-ready();
    margin-bottom: $spacer;
}

.c-hero--single {
    .c-breadcrumbs {
        margin-bottom: 1rem;
    }

    .c-hero__content {
        padding-top: 2rem;
        padding-bottom: 3rem;
    }
}
