.c-expand-menu {}

.c-expand-menu__toggle {
    color: $accent;
}

.c-expand-menu__item--d1 {
    margin-bottom: 0;
}

.c-expand-menu__list--d2 {
    border: rem(2) solid $gray-100;
    border-top: 0;
}

.c-expand-menu__link--d2 {
    padding: $spacer--s $spacer;
}
