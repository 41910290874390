.c-media-block {
    margin-left: 0;
    margin-right: 0;
}

.c-media-block--card {
    @include make-card;
    background-color: $white;
    align-items: stretch;
    text-align: center;

    @include media-breakpoint-down(m) {
        max-width: 30rem;
        margin-left: auto;
        margin-right: auto;
    }

    .c-media-block__content {
        @include media-breakpoint-only(s) {
            padding: 0 $spacer--l $spacer--xl;
        }
        @include media-breakpoint-only(m) {
            padding: 0 $spacer--2xl $spacer--2xl;
        }
        @include media-breakpoint-up(l) {
            padding: $spacer--2xl;
        }
    }
    .c-media-block__media {
        padding: 0;

    }
    .c-media-block__image {
        width: 100%;
        height: 100%;
    }
    .o-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
