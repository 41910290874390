@mixin make-button--outline-accent() {
    @include make-button-variant(
        transparent,
        $accent,
        $accent,
        $accent,
        $accent,
        $white
    )
}

@mixin make-button--outline-white() {
    @include make-button-variant(
        transparent,
        $white,
        $white,
        $error,
        $error,
        $white
    )
}

@mixin make-button--outline-gray() {
    @include make-button-variant(
        transparent,
        $gray-600,
        $gray-600,
        $error,
        $error,
        $white
    )
}

.c-button--outline-accent {
    @include make-button--outline-accent();
}

.c-button--outline-white {
    @include make-button--outline-white();
}

.c-button--outline-gray {
    @include make-button--outline-gray();
}

.c-button__icon--after {
    margin-left: $spacer--2xs;
}
.c-button__icon--before {
    margin-right: $spacer--2xs;
}
