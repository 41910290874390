.c-site-footer {
    color: $footer-color;
    background-color: $footer-background-color;
    padding-top: $footer-padding-top;
    padding-bottom: $footer-padding-bottom;
    margin-top: auto;
    text-align: center;
}

.c-site-footer__top {
    padding-bottom: $spacer--2xl;
    border-bottom: $spacer--2xs solid $primary;
    margin-bottom: $spacer--2xl;

    @include media-breakpoint-down(m) {
        .c-button {
            display: none;
        }
    }

    @include media-breakpoint-up(l) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.c-site-footer__logo {
    max-width: 20rem;
    width: 100%;

    @include media-breakpoint-down(m) {
        margin: 0 auto;
    }

    .c-logo {
        background-image: url('#{$footer-logo-path}');
    }
}

.c-site-footer__menu {
    margin-bottom: 4rem;

    @include media-breakpoint-down(m) {
        @include type-scale(base);
    }
    @include media-breakpoint-up(l) {
        @include type-scale(s);
    }

    .c-menu__list {
        @include media-breakpoint-only(m) {
            columns: 2;
        }
        @include media-breakpoint-up(l) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: -$spacer--xs;
            margin-right: -$spacer--xs;
        }
    }

    .c-menu__item {
        letter-spacing: rem(1);

        @include media-breakpoint-down(m) {
            margin-bottom: 1rem;
        }
        @include media-breakpoint-up(l) {
            padding-left: $spacer--xs;
            padding-right: $spacer--xs;
        }
    }

    .c-menu__link {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }
}

.c-site-footer__disclaimer {
    @include type-scale(s, 1.5);
    margin-left: auto;
    margin-right: auto;
    font-style: normal;
    color: $footer-disclaimer-color;

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }

    .c-disclaimer {
        font-size: $font-size--base * 0.75;
    }
}

.c-site-footer__disclaimer-part {
    @include media-breakpoint-up(l) {
        display: inline-block;
    }
}
.c-site-footer__disclaimer-part--copyright {
    width: 100%;
    span {
        display: inline-block;

        @include media-breakpoint-down(m) {
            &:first-child {
                display: block;
            }
            &:last-child {
                &:before {
                    content: ' | ';
                }
            }
        }

        @include media-breakpoint-up(l) {
            &:before {
                content: ' | ';
            }
            &:first-child {
                &:before {
                    content: '';
                }
            }
        }
    }
}

.c-site-footer__disclaimer-part--address {
    margin-top: $spacer--s;
    width: 100%;


    span {
        display: inline-block;
    }
}