.c-page__main {
    margin-bottom: 0;
}

.c-page--backpage {
    margin-bottom: 4rem;

    .c-page__content {
        flex-direction: row;
    }

    .c-page__main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .c-attribution {
        width: 100%;
        max-width: $wysiwyg-max-width;
    }

}

.c-page__aside {
    > .c-widget {
        @include media-breakpoint-up(l) {
            max-width: 18rem;
            margin-right: 0;
        }
    }
}
