.c-widget__title {
    font-family: $font-family--serif;
    display: block;
    background-color: $secondary;
    color: $white;
    padding: $spacer $spacer--m;
    margin-bottom: 0;
}


.c-widget--menu {
    margin-bottom: $spacer--3xl;
}

.c-widget--sticky {
    @include media-breakpoint-up(l) {
        top: 7rem;
        transition: 0.3s top ease-in-out;

        .hide-site-header & {
            top: 2rem;
        }
    }
}
