.c-alert--browser-update {
    background: #bc1822;
    padding: $spacer--xs;
    box-shadow: $box-shadow;
    flex-direction: column;
    border: none;
    border-radius: 0;
    margin: 0;
    display: none;

    a {
        color: $white;
    }

    p {
        @include type-scale(s);
        margin: 0;
        text-align: center;
        color: $white;
    }
}
.c-site-header {
    top: auto;
}
