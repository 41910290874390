.c-calculator {

    .c-hero--featured {
        padding-bottom: $spacer--3xl;

        @include media-breakpoint-up(l) {
            padding-bottom: $spacer--6xl;
        }
    }

    .calculation {
        background-color: color-step($accent, 50);
        border-radius: 0.5rem;
        padding: $spacer--l;
        text-align: center;
    }

    .calculation__result {
        @include make-h1;
        margin: 0;
        line-height: 1;
        display: block;
        font-weight: bold;
    }

    .calculator__title {
        @include type-scale(l);
        margin: $spacer--m 0;
        text-align: center;
    }
}
