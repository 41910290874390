.c-attribution {
    margin-top: 1rem;
}

.c-attribution--footer {
    margin-top: 3rem;
    border-top: rem(1) solid theme-color('light');
    padding-top: 3rem;

    .c-attribution__additional {
        display: flex;
    }
    .c-attribution__profile {
        flex-grow: 1;
        flex-shrink: 1;
    }
}
