.page-template-contact {
    .c-action-block {
        h3 {
            margin-bottom: 0;
            margin-top: 2.5rem;
        }

        p {
            margin-top: 0.2rem;
        }
    }
}
