.c-site-menu__list {
    @include list-unstyled;
    display: flex;
    flex-grow: 1;

    .c-site-menu__item--has-children:hover > & {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s, 0s;
    }
}

.c-site-menu__list--d2 {
    position: absolute;
    top: 100%;
    right: 5%;
    background-color: $white;
    flex-direction: column;
    min-width: 15rem;
    max-width: 48rem;
    padding: $spacer--2xl;
    visibility: hidden;
    opacity: 0;
    transition-property: opacity, visibility;
    transition-duration: 0.2s, 0s;
    transition-delay: 0s, 0.2s;
    box-shadow: $box-shadow--l;
    width: 100%;

    @include media-breakpoint-up(xl) {
        right: 11%;
    }

    .c-site-menu__item--has-children:hover & {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s, 0s;
    }

    .c-cta {
        margin: 0;
        color: $dark;
        line-height: normal;
    }

    .c-site-menu__item--d1:last-child & {
        width: auto;
    }
}





.c-site-menu__item {}

.c-site-menu__item--d1 {
    @include type-scale(s, false);
    line-height: $site-header-height--desktop;
    height: $site-header-height--desktop;
    padding: 0 $spacer;
    color: $site-header-color;
    position: relative;
    background-color: transparent;
    transition: background-color 0.2s ease;
    position: inherit;

    &:hover {
        background-color: transparent;
        color: $site-header-hover-color;
        border-bottom: $spacer--2xs solid $primary;
    }

    @include media-breakpoint-only(l) {
        padding-left: $spacer--s;
        padding-right: $spacer--s;
    }
}

.c-site-menu__item--d2 {
    width: 100%;
    left: auto;
    right: 0;
    margin: $spacer--s 0;
    color: $dark;
    padding: $spacer--xs 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}






.c-site-menu__link {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: $site-header-hover-color;
    }
}

.c-site-menu__link--d1 {
    display: inline-block;
    line-height: inherit;
    color: inherit;

    .c-site-menu__item--has-children:hover & {
        color: inherit;
    }

    @include media-breakpoint-up(l) {

        &:after {
            .c-site-menu__item--has-children & {
                @include make-icon;
                margin-left: 0.25rem;
                top: 0.0625rem;
                position: relative;
                content: icon('chevron-down');
            }
        }
    }
}

.c-site-menu__link--d2 {
    @include type-scale(s, 1);
    display: block;
    color: inherit;

    &:hover {
        color: inherit;
        text-decoration: underline;
    }

    .c-site-menu__item--has-children:hover & {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s, 0s;
    }
}



.c-site-menu__dropdown-col {
    flex: 1;
    padding: 0 $spacer;
}