@charset "UTF-8";
*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 2;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 400;
}

@-ms-viewport {
  width: device-width;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  color: #4d4d4d;
  text-align: left;
  background-color: #ffffff;
  min-width: 20rem;
  width: 100%;
  font-size: 1rem;
  line-height: 2;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  padding-left: 0;
  list-style: none;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 0.75em;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2e4f63;
  text-decoration: underline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #3192b2;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code,
kbd,
pre,
samp {
  font-family: monospace;
  font-size: inherit;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: #333333;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.25rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 0.125rem dotted;
  outline: 0.25rem auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -0.125rem;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

@media (max-width: 54.9375em) {
  html.admin {
    margin-top: 0 !important;
  }
}

@media (max-width: 54.9375em) {
  #wpadminbar {
    display: none !important;
  }
}

.l-page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.c-page {
  flex: 1;
}

.l-container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 64rem;
}

.l-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.c-page--one-column > .l-container {
  max-width: 42rem;
}

.c-page__main {
  margin-bottom: 4rem;
}

.c-page__aside {
  margin-bottom: 4rem;
}

.c-page__footer {
  padding-bottom: 3rem;
}

.c-page--two-column .c-page__content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}
.c-page--two-column .c-page__main {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 55em) {
  .c-page--two-column .c-page__main {
    width: 66.6666666667%;
  }
}
.c-page--two-column .c-page__aside {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 55em) {
  .c-page--two-column .c-page__aside {
    width: 33.3333333333%;
  }
}

.c-page--one-column .c-page__header {
  margin-bottom: 0;
}
.c-page--one-column .c-page__content .c-hummingbird {
  max-width: 75rem;
  margin: 1rem auto;
  padding: 0 1rem;
  position: relative;
}
.c-page--one-column .c-content__after {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}
.c-page--one-column .c-content__after .c-share {
  text-align: center;
}
.c-page--one-column .c-content__after .c-share__list {
  justify-content: center;
}
.c-page--one-column .c-content__after > * {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}
.c-page--one-column .c-content__after > :first-child {
  margin-top: 2rem;
  border-top: none;
  padding-top: 0;
}
.c-page--one-column .c-content__after > :last-child {
  margin-bottom: 2rem;
}
.c-page--one-column .c-page__main {
  margin-bottom: 0;
}

.c-button {
  font-size: 1rem;
  line-height: 1;
  border-radius: 1.5rem;
  border-width: 0.125rem;
  border-style: solid;
  border-color: #eeeeee;
  padding: 0.875rem 2.5rem;
  color: #333333;
  background-color: #eeeeee;
  text-transform: uppercase;
  letter-spacing: rem(1);
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
.u-hover-children:hover .c-button, .c-button:hover {
  text-decoration: none;
  border-color: #a7a7a7;
  background-color: #a7a7a7;
  color: #333333;
}
.c-button:focus {
  outline: 0;
}

.c-button--primary {
  background-color: #3cba8c;
  border-color: #3cba8c;
  color: #ffffff;
}
.u-hover-children:hover .c-button--primary, .c-button--primary:hover {
  background-color: #2a8262;
  border-color: #2a8262;
  color: #ffffff;
}

.c-button--secondary {
  background-color: #2e4f63;
  border-color: #2e4f63;
  color: #ffffff;
}
.u-hover-children:hover .c-button--secondary, .c-button--secondary:hover {
  background-color: #203745;
  border-color: #203745;
  color: #ffffff;
}

.c-button--accent {
  background-color: #3192b2;
  border-color: #3192b2;
  color: #ffffff;
}
.u-hover-children:hover .c-button--accent, .c-button--accent:hover {
  background-color: #22667d;
  border-color: #22667d;
  color: #ffffff;
}

.c-button--success {
  background-color: #3cba8c;
  border-color: #3cba8c;
  color: #ffffff;
}
.u-hover-children:hover .c-button--success, .c-button--success:hover {
  background-color: #2a8262;
  border-color: #2a8262;
  color: #ffffff;
}

.c-button--error {
  background-color: #d22323;
  border-color: #d22323;
  color: #ffffff;
}
.u-hover-children:hover .c-button--error, .c-button--error:hover {
  background-color: #931919;
  border-color: #931919;
  color: #ffffff;
}

.c-button--light {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #333333;
}
.u-hover-children:hover .c-button--light, .c-button--light:hover {
  background-color: #a7a7a7;
  border-color: #a7a7a7;
  color: #333333;
}

.c-button--dark {
  background-color: #333333;
  border-color: #333333;
  color: #ffffff;
}
.u-hover-children:hover .c-button--dark, .c-button--dark:hover {
  background-color: #242424;
  border-color: #242424;
  color: #ffffff;
}

.c-button--twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #ffffff;
}
.u-hover-children:hover .c-button--twitter, .c-button--twitter:hover {
  background-color: #1471a9;
  border-color: #1471a9;
  color: #ffffff;
}

.c-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #ffffff;
}
.u-hover-children:hover .c-button--facebook, .c-button--facebook:hover {
  background-color: #293e6a;
  border-color: #293e6a;
  color: #ffffff;
}

.c-button--google-plus {
  background-color: #ea4335;
  border-color: #ea4335;
  color: #ffffff;
}
.u-hover-children:hover .c-button--google-plus, .c-button--google-plus:hover {
  background-color: #a42f25;
  border-color: #a42f25;
  color: #ffffff;
}

.c-button--linkedin {
  background-color: #0077b5;
  border-color: #0077b5;
  color: #ffffff;
}
.u-hover-children:hover .c-button--linkedin, .c-button--linkedin:hover {
  background-color: #00537f;
  border-color: #00537f;
  color: #ffffff;
}

.c-button--pinterest {
  background-color: #bd081c;
  border-color: #bd081c;
  color: #ffffff;
}
.u-hover-children:hover .c-button--pinterest, .c-button--pinterest:hover {
  background-color: #840614;
  border-color: #840614;
  color: #ffffff;
}

.c-button--instagram {
  background-color: #f56040;
  border-color: #f56040;
  color: #ffffff;
}
.u-hover-children:hover .c-button--instagram, .c-button--instagram:hover {
  background-color: #ac432d;
  border-color: #ac432d;
  color: #ffffff;
}

.c-button--outline {
  background-color: transparent;
  border-color: #3cba8c;
  color: #3cba8c;
}
.u-hover-children:hover .c-button--outline, .c-button--outline:hover {
  background-color: #3cba8c;
  border-color: #3cba8c;
  color: #ffffff;
}

.c-button--gradient {
  background-image: linear-gradient(90deg, #084c7f, #34c6f4, #084c7f);
  background-size: 200% 200%;
  background-position: left;
  border-color: transparent;
  color: #ffffff;
}
.u-hover-children:hover .c-button--gradient, .c-button--gradient:hover {
  border-color: transparent;
  background-position: right;
  color: #ffffff;
}

.c-button--xs {
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0.75rem;
  border-width: 0.0625rem;
  padding: 0.1875rem 0.5rem;
  letter-spacing: 0.03125rem;
  font-weight: normal;
}

.c-button--s {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  border-radius: 1.5rem;
  border-width: 0.125rem;
  padding: 0.875rem 1.5rem;
  letter-spacing: rem(1);
  font-weight: bold;
}

.c-button--l {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  border-radius: 2rem;
  border-width: 0.25rem;
  padding: 1rem 2.5rem;
  letter-spacing: 0.125rem;
  font-weight: 700;
}
@media (min-width: 35em) {
  .c-button--l {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
@media (min-width: 55em) {
  .c-button--l {
    font-size: 1.1875rem;
    line-height: 1.0526315789;
  }
}
@media (min-width: 75em) {
  .c-button--l {
    font-size: 1.25rem;
    line-height: 1;
  }
}

.c-button--link {
  background: none;
  border-color: transparent;
  padding-left: 0;
  padding-right: 0;
  color: #3cba8c;
  font-weight: bold;
}
@supports (text-decoration-color: transparent) {
  .c-button--link {
    text-decoration: underline;
    text-decoration-color: transparent;
  }
}
.u-hover-children:hover .c-button--link, .c-button--link:hover {
  color: #2a8262;
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
}

.c-button--line {
  background: none;
  border-color: transparent;
  color: #ffffff;
  font-weight: bold;
  border-radius: 0;
  padding: 0.25rem 0.5rem;
  border-bottom: 0.125rem solid #ffffff;
  border-left: 0;
  border-right: 0;
}
.u-hover-children:hover .c-button--line, .c-button--line:hover {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: #ffffff;
}

.c-button--icon {
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  text-align: center;
  padding: 0;
  border: 0;
  font-size: inherit;
  line-height: 1;
}
.c-button--icon.c-button--xs {
  width: 1.5rem;
  height: 1.5rem;
}
.c-button--icon.c-button--s {
  width: 2rem;
  height: 2rem;
}

.c-button--ghost {
  color: inherit;
  background-color: transparent;
  border-color: transparent;
}
.u-hover-children:hover .c-button--ghost, .c-button--ghost:hover {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
}

.c-form__label {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  margin-bottom: 0.25rem;
  font-weight: 700;
}

.c-form__label--error {
  color: #d22323;
  display: none;
}
.c-form__group.has-error .c-form__label--error {
  display: block;
}

.c-form__control,
.c-form__textarea {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  width: 100%;
  padding: 0.4375rem;
  color: #1a1a1a;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  height: 3rem;
  border: 0.0625rem solid #cccccc;
}
.c-form__control::-ms-expand,
.c-form__textarea::-ms-expand {
  background-color: transparent;
  border: 0;
}
.c-form__group.has-error .c-form__control,
.c-form__group.has-error .c-form__textarea {
  border-color: rgba(210, 35, 35, 0.5);
}

.c-form__textarea {
  height: 6rem;
}

.c-form__checkbox-input,
.c-form__radio-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.c-form__label--checkbox,
.c-form__label--radio {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 400;
}

.c-form__label--checkbox:before,
.c-form__label--radio:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  width: 1.25rem;
  text-align: left;
}

.c-form__label--checkbox:before {
  content: "\f096";
}

.c-form__label--radio:before {
  content: "\e905";
}

.c-form__checkbox-input:checked + .c-form__label--checkbox:before {
  content: "\f046";
}

.c-form__radio-input:checked + .c-form__label--radio:before {
  content: "\e904";
}

/*!
 * Hamburger for ARES
*/
.c-hamburger {
  display: block;
  cursor: pointer;
  width: 2rem;
  height: 1.125rem;
  position: relative;
}

@media (max-width: 34.9375em) {
  .c-hamburger {
    width: 1.5rem;
    height: 0.75rem;
  }
}

.c-hamburger__bar {
  display: block;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0;
}
@media (max-width: 34.9375em) {
  .c-hamburger__bar {
    height: 0.125rem;
    border-radius: 0.0625rem;
    margin-top: -0.0625rem;
  }
}
@media (min-width: 35em) {
  .c-hamburger__bar {
    height: 0.1875rem;
    border-radius: 0.09375rem;
    margin-top: -0.09375rem;
  }
}

.c-hamburger__bar--1,
.c-hamburger__bar--3 {
  transition: transform 0.1s ease, top 0.1s ease 0.1s;
}
.c-hamburger.is-active .c-hamburger__bar--1,
.c-hamburger.is-active .c-hamburger__bar--3 {
  transition: transform 0.1s ease 0.1s, top 0.1s ease;
}

.c-hamburger__bar--1 {
  top: 0%;
  transform: rotate(0deg);
}
.c-hamburger.is-active .c-hamburger__bar--1 {
  top: 50%;
  transform: rotate(45deg);
}

.c-hamburger__bar--2 {
  top: 50%;
  opacity: 1;
  transition: opacity 0.1s ease 0.1s;
}
.c-hamburger.is-active .c-hamburger__bar--2 {
  opacity: 0;
  transition: opacity 0.1s ease 0.1s;
}

.c-hamburger__bar--3 {
  top: 100%;
  transform: rotate(0deg);
}
.c-hamburger.is-active .c-hamburger__bar--3 {
  top: 50%;
  transform: rotate(-45deg);
}

h1,
.h1 {
  font-size: 1.625rem;
  line-height: 1.2307692308;
  font-family: Aleo, Georgia, serif;
  font-weight: 400;
  color: #333333;
}
@media (min-width: 35em) {
  h1,
  .h1 {
    font-size: 1.75rem;
    line-height: 1.1428571429;
  }
}
@media (min-width: 55em) {
  h1,
  .h1 {
    font-size: 2.0625rem;
    line-height: 1.2121212121;
  }
}
@media (min-width: 75em) {
  h1,
  .h1 {
    font-size: 2.4375rem;
    line-height: 1.2307692308;
  }
}

h2,
.h2 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: Aleo, Georgia, serif;
  font-weight: 700;
  color: #333333;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
@media (min-width: 35em) {
  h2,
  .h2 {
    font-size: 1.3125rem;
    line-height: 1.1428571429;
  }
}
@media (min-width: 55em) {
  h2,
  .h2 {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 75em) {
  h2,
  .h2 {
    font-size: 1.5625rem;
    line-height: 1.28;
  }
}

h3,
.h3 {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-family: Aleo, Georgia, serif;
  font-weight: 700;
  color: #2e4f63;
  margin-top: 0;
  margin-bottom: 2rem;
}
@media (min-width: 35em) {
  h3,
  .h3 {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
@media (min-width: 55em) {
  h3,
  .h3 {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 75em) {
  h3,
  .h3 {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

h4,
.h4 {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Aleo, Georgia, serif;
  font-weight: 700;
  color: #333333;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

h5,
.h5 {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-family: Aleo, Georgia, serif;
  font-weight: 700;
  color: #333333;
}
@media (min-width: 35em) {
  h5,
  .h5 {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
@media (min-width: 55em) {
  h5,
  .h5 {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 75em) {
  h5,
  .h5 {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Aleo, Georgia, serif;
  font-weight: 700;
  color: #333333;
}

.c-link-list {
  padding-left: 1rem;
  list-style-type: none;
}

.c-ol {
  margin-bottom: 0;
  padding-left: 1.5rem;
}

.c-ol__item {
  margin-bottom: 0.75rem;
}
.c-ol__item:last-child {
  margin-bottom: 0;
}

.c-ul {
  margin-bottom: 0;
  padding-left: 2rem;
  list-style: initial;
}

.c-ul__item {
  margin-bottom: 1rem;
}
.c-ul__item:last-child {
  margin-bottom: 0;
}

.c-ul--split {
  column-count: 2;
  column-gap: 2.5rem;
}

.c-logo {
  display: block;
  text-indent: -10000rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 0;
  padding-top: 16.5631469979%;
  background-image: url("../assets/img/logo--white.svg");
}

.c-hummingbird {
  font-size: 1rem;
  line-height: 2;
  border-left: 0.125rem solid #ffffff;
  padding-left: 1.25rem;
  margin-top: 1.25rem;
}

.c-caption {
  font-size: 1.125rem;
  line-height: 2;
  font-style: italic;
}
@media (min-width: 35em) {
  .c-caption {
    font-size: 1.125rem;
    line-height: 2;
  }
}
@media (min-width: 55em) {
  .c-caption {
    font-size: 1.1875rem;
    line-height: 2.1052631579;
  }
}
@media (min-width: 75em) {
  .c-caption {
    font-size: 1.25rem;
    line-height: 2;
  }
}

.c-disclaimer {
  font-size: 0.875rem;
  line-height: 2;
  font-style: italic;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

td,
th {
  padding: 0.5rem 1rem;
}

thead th,
thead td {
  background-color: #3cba8c;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
}

tbody td,
tbody th {
  border-bottom: 0.0625rem solid #eeeeee;
}
tbody tr:last-child td,
tbody tr:last-child th {
  border-bottom: 0;
}

.c-button--outline-accent {
  background-color: transparent;
  border-color: #3192b2;
  color: #3192b2;
}
.u-hover-children:hover .c-button--outline-accent, .c-button--outline-accent:hover {
  background-color: #3192b2;
  border-color: #3192b2;
  color: #ffffff;
}

.c-button--outline-white {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;
}
.u-hover-children:hover .c-button--outline-white, .c-button--outline-white:hover {
  background-color: #d22323;
  border-color: #d22323;
  color: #ffffff;
}

.c-button--outline-gray {
  background-color: transparent;
  border-color: #666666;
  color: #666666;
}
.u-hover-children:hover .c-button--outline-gray, .c-button--outline-gray:hover {
  background-color: #d22323;
  border-color: #d22323;
  color: #ffffff;
}

.c-button__icon--after {
  margin-left: 0.25rem;
}

.c-button__icon--before {
  margin-right: 0.25rem;
}

.c-ul__item:first-child {
  margin-top: 0;
}

.c-disclaimer {
  font-style: italic;
}

thead td,
thead th {
  background-color: rgb(28, 41, 51);
  text-align: center;
}

tr.u-primary--dark td,
tr.u-primary--dark th,
td.u-primary--dark,
th.u-primary--dark {
  background-color: rgb(44, 126, 95);
  color: #ffffff;
  border-bottom: 0.0625rem solid rgb(44, 126, 95);
}

tr.u-primary td,
tr.u-primary th,
td.u-primary,
th.u-primary {
  background-color: #3cba8c;
  color: #ffffff;
  border-bottom: 0.0625rem solid #3cba8c;
}

tr.u-primary--light td,
tr.u-primary--light th,
td.u-primary--light,
th.u-primary--light {
  background-color: rgb(154, 219, 195);
  color: #333333;
  border-bottom: 0.0625rem solid rgb(154, 219, 195);
}

tr.u-primary--xlight td,
tr.u-primary--xlight th,
td.u-primary--xlight,
th.u-primary--xlight {
  background-color: rgb(232, 247, 241);
  color: #333333;
  border-bottom: 0.0625rem solid rgb(232, 247, 241);
}

tr.u-secondary--dark td,
tr.u-secondary--dark th,
td.u-secondary--dark,
th.u-secondary--dark {
  background-color: rgb(34, 54, 67);
  color: #ffffff;
  border-bottom: 0.0625rem solid rgb(34, 54, 67);
}

tr.u-secondary td,
tr.u-secondary th,
td.u-secondary,
th.u-secondary {
  background-color: #2e4f63;
  color: #ffffff;
  border-bottom: 0.0625rem solid #2e4f63;
}

tr.u-secondary--light td,
tr.u-secondary--light th,
td.u-secondary--light,
th.u-secondary--light {
  background-color: rgb(146, 163, 174);
  color: #333333;
  border-bottom: 0.0625rem solid rgb(146, 163, 174);
}

tr.u-secondary--xlight td,
tr.u-secondary--xlight th,
td.u-secondary--xlight,
th.u-secondary--xlight {
  background-color: rgb(230, 234, 236);
  color: #333333;
  border-bottom: 0.0625rem solid rgb(230, 234, 236);
}

tr.u-accent--dark td,
tr.u-accent--dark th,
td.u-accent--dark,
th.u-accent--dark {
  background-color: rgb(36, 99, 121);
  color: #ffffff;
  border-bottom: 0.0625rem solid rgb(36, 99, 121);
}

tr.u-accent td,
tr.u-accent th,
td.u-accent,
th.u-accent {
  background-color: #3192b2;
  color: #ffffff;
  border-bottom: 0.0625rem solid #3192b2;
}

tr.u-accent--light td,
tr.u-accent--light th,
td.u-accent--light,
th.u-accent--light {
  background-color: rgb(148, 198, 215);
  color: #333333;
  border-bottom: 0.0625rem solid rgb(148, 198, 215);
}

tr.u-accent--xlight td,
tr.u-accent--xlight th,
td.u-accent--xlight,
th.u-accent--xlight {
  background-color: rgb(230, 242, 246);
  color: #333333;
  border-bottom: 0.0625rem solid rgb(230, 242, 246);
}

tr.u-gray--dark td,
tr.u-gray--dark th,
td.u-gray--dark,
th.u-gray--dark {
  background-color: #333333;
  color: #ffffff;
  border-bottom: 0.0625rem solid #333333;
}

tr.u-gray td,
tr.u-gray th,
td.u-gray,
th.u-gray {
  background-color: #666666;
  color: #ffffff;
  border-bottom: 0.0625rem solid #666666;
}

tr.u-gray--light td,
tr.u-gray--light th,
td.u-gray--light,
th.u-gray--light {
  background-color: #cccccc;
  color: #333333;
  border-bottom: 0.0625rem solid #cccccc;
}

tr.u-gray--xlight td,
tr.u-gray--xlight th,
td.u-gray--xlight,
th.u-gray--xlight {
  background-color: #eeeeee;
  color: #333333;
  border-bottom: 0.0625rem solid #eeeeee;
}

.c-alert {
  padding: 1rem;
  border: 0.0625rem solid #cccccc;
  background-color: #eeeeee;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}

.c-alert__icon--before {
  margin-right: 1rem;
}

.c-alert--error {
  border-color: #d22323;
  color: #d22323;
  background: rgba(210, 35, 35, 0.1);
}

.c-banner {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  padding: 0.25rem;
}

.c-banner__content {
  font-size: 0.875rem;
  line-height: 2;
  padding: 0.25rem 0.75rem;
  flex-grow: 1;
}

.c-banner__close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-banner--dark {
  background-color: #333333;
  color: #ffffff;
}

.c-banner--fixed {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  transform: translateY(-100%);
  transition: 0.2s transform ease-in-out;
}
.c-banner--fixed.is-active {
  transform: translateY(0%);
}

.c-blockquote {
  max-width: 40rem;
  width: 100%;
  margin: 2rem auto;
  text-align: center;
}

.c-blockquote__image {
  display: block;
  margin: 0 auto;
  border-radius: 100%;
}

.c-blockquote__quote {
  font-size: 1.25rem;
  line-height: 2;
  margin: 1rem auto;
  font-family: "Aleo", "Georgia", serif;
}
@media (min-width: 35em) {
  .c-blockquote__quote {
    font-size: 1.3125rem;
    line-height: 2.0952380952;
  }
}
@media (min-width: 55em) {
  .c-blockquote__quote {
    font-size: 1.4375rem;
    line-height: 2.0869565217;
  }
}
@media (min-width: 75em) {
  .c-blockquote__quote {
    font-size: 1.5625rem;
    line-height: 2.08;
  }
}

.c-blockquote__title {
  font-size: 1.125rem;
  line-height: 2;
  display: block;
}
@media (min-width: 35em) {
  .c-blockquote__title {
    font-size: 1.125rem;
    line-height: 2;
  }
}
@media (min-width: 55em) {
  .c-blockquote__title {
    font-size: 1.1875rem;
    line-height: 2.1052631579;
  }
}
@media (min-width: 75em) {
  .c-blockquote__title {
    font-size: 1.25rem;
    line-height: 2;
  }
}

.c-blockquote__subtitle {
  font-size: 0.875rem;
  line-height: 2;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  color: #666666;
}

.c-blockquote__sources {
  font-size: 0.75rem;
  line-height: 1.6666666667;
  margin-top: 0.5rem;
}

.c-blockquote--left {
  text-align: left;
}

.c-blockquote--right {
  text-align: right;
}

.c-blockquote--small .c-blockquote__quote {
  font-size: 1.125rem;
  line-height: 2;
}
@media (min-width: 35em) {
  .c-blockquote--small .c-blockquote__quote {
    font-size: 1.125rem;
    line-height: 2;
  }
}
@media (min-width: 55em) {
  .c-blockquote--small .c-blockquote__quote {
    font-size: 1.1875rem;
    line-height: 2.1052631579;
  }
}
@media (min-width: 75em) {
  .c-blockquote--small .c-blockquote__quote {
    font-size: 1.25rem;
    line-height: 2;
  }
}
.c-blockquote--small .c-blockquote__title {
  font-size: 0.875rem;
  line-height: 2;
}
.c-blockquote--small .c-blockquote__subtitle {
  font-size: 0.75rem;
  line-height: 2;
}

.c-action-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  padding: 2rem;
  text-align: center;
  text-decoration: none;
  border-radius: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #4d4d4d;
  margin-left: auto;
  margin-right: auto;
}
.c-action-block:hover {
  text-decoration: none;
  color: #4d4d4d;
}

.c-action-block__image {
  margin-bottom: 1rem;
}

.c-action-block__title {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.25;
  font-family: Aleo, Georgia, serif;
  font-weight: 700;
  color: #333333;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

.c-action-block__copy {
  color: inherit;
}

.c-action-block__button {
  margin-top: 1rem;
}

.c-action-block--card {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  max-width: none;
}

a.c-action-block--card {
  transform: translateY(0);
  transition: all 0.2s ease-in-out;
}
a.c-action-block--card:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.375rem 0.5rem 0.25rem rgba(0, 0, 0, 0.2);
}

.c-action-block--white {
  background-color: #ffffff;
}

.c-action-block--light {
  background-color: #eeeeee;
}

.c-action-block--card-image-left {
  text-align: left;
}
.c-action-block--card-image-left .c-action-block__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.c-action-block--card-image-left .c-action-block__image {
  flex: 0 0 4rem;
  margin: 0;
}
.c-action-block--card-image-left .c-action-block__title {
  flex: 1 1 auto;
  padding-left: 1rem;
  margin: 0;
}
.c-action-block--card-image-left .c-action-block__copy {
  flex: 0 1 100%;
  margin-top: 1rem;
}

.c-profile-block {
  position: relative;
  display: inline-flex;
}
.c-profile-block a {
  position: relative;
  z-index: 1;
}

.c-profile-block__image-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

.c-profile-block__image {
  border-radius: 50%;
  margin-right: 1rem;
}

.c-profile-block__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-profile-block__before {
  font-size: 0.875rem;
  line-height: 1.1428571429;
}

.c-profile-block__name {
  display: block;
  font-weight: bold;
}
@supports (text-decoration-color: transparent) {
  .c-profile-block__name {
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all 0.2s ease-in-out;
  }
}

a.c-profile-block__name {
  position: inherit;
  position: inherit;
  z-index: 0;
}
a.c-profile-block__name:before {
  content: "";
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}
a.c-profile-block__name:hover {
  color: #3cba8c;
  text-decoration: underline;
  text-decoration-color: #3cba8c;
}

.c-profile-block__title {
  font-size: 0.875rem;
  line-height: 1.1428571429;
}

.c-profile-block__after {
  display: block;
  font-size: 0.75rem;
  line-height: 2;
}

.c-profile-block__button {
  margin-top: 0.5rem;
}

.c-profile-block--stacked {
  flex-direction: column;
  text-align: center;
}
.c-profile-block--stacked .c-profile-block__image {
  margin-right: 0;
  margin-bottom: 1rem;
}

.c-breadcrumbs {
  font-size: 0.875rem;
  line-height: 2;
  color: rgba(51, 51, 51, 0.85);
}
.c-breadcrumbs a {
  color: rgba(51, 51, 51, 0.85);
  text-decoration: none;
}
.c-breadcrumbs a:hover {
  color: #333333;
  text-decoration: underline;
}
.c-breadcrumbs .breadcrumb_last {
  color: #333333;
  font-weight: bold;
}

.c-breadcrumbs--white {
  color: rgba(255, 255, 255, 0.85);
}
.c-breadcrumbs--white a {
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
}
.c-breadcrumbs--white a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.c-breadcrumbs--white .breadcrumb_last {
  color: #ffffff;
  font-weight: bold;
}

.c-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-top: 0.125rem solid #eeeeee;
  border-bottom: 0.125rem solid #eeeeee;
  max-width: 48rem;
  margin: 2rem auto;
  position: relative;
}

.c-cta__icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.c-cta__image {
  margin-bottom: 1rem;
}

.c-cta__title {
  font-size: 1.25rem;
  line-height: 2;
  font-weight: normal;
  font-family: "Aleo", "Georgia", serif;
  max-width: 40rem;
  margin: 0 auto;
}
@media (min-width: 35em) {
  .c-cta__title {
    font-size: 1.3125rem;
    line-height: 2.0952380952;
  }
}
@media (min-width: 55em) {
  .c-cta__title {
    font-size: 1.4375rem;
    line-height: 2.0869565217;
  }
}
@media (min-width: 75em) {
  .c-cta__title {
    font-size: 1.5625rem;
    line-height: 2.08;
  }
}

.c-cta__description {
  max-width: 40rem;
  margin: 1rem auto;
  text-align: center;
}

.c-cta__button {
  margin-top: 1rem;
}

.c-cta--video {
  margin: 0;
  border: 0;
}
@media (max-width: 34.9375em) {
  .c-cta--video {
    padding: 3rem 1rem 1rem;
  }
}
@media (min-width: 35em) {
  .c-cta--video {
    padding: 1.5rem 3rem 2.5rem;
  }
}
@media (max-width: 34.9375em) {
  .c-cta--video .c-cta__title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media (max-width: 34.9375em) and (min-width: 35em) {
  .c-cta--video .c-cta__title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media (max-width: 34.9375em) and (min-width: 55em) {
  .c-cta--video .c-cta__title {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (max-width: 34.9375em) and (min-width: 75em) {
  .c-cta--video .c-cta__title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
@media (min-width: 35em) {
  .c-cta--video .c-cta__title {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 35em) and (min-width: 35em) {
  .c-cta--video .c-cta__title {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 35em) and (min-width: 55em) {
  .c-cta--video .c-cta__title {
    font-size: 1.75rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 35em) and (min-width: 75em) {
  .c-cta--video .c-cta__title {
    font-size: 1.9375rem;
    line-height: 1.2903225806;
  }
}
@media (max-width: 34.9375em) {
  .c-cta--video .c-cta__button {
    margin-top: 0.75rem;
  }
}
.c-cta--video .c-button {
  background-color: #3192b2;
  border-color: #3192b2;
  color: #ffffff;
}
@media (max-width: 34.9375em) {
  .c-cta--video .c-button {
    font-size: 0.75rem;
    line-height: 1;
    border-radius: 0.75rem;
    border-width: 0.0625rem;
    padding: 0.1875rem 0.5rem;
    letter-spacing: 0.03125rem;
    font-weight: normal;
  }
}
.u-hover-children:hover .c-cta--video .c-button, .c-cta--video .c-button:hover {
  background-color: #22667d;
  border-color: #22667d;
  color: #ffffff;
}

.c-cta--button {
  margin: 0;
  padding: 0;
  border: 0;
  display: block;
}
.c-cta--button > * {
  display: none;
}
.c-cta--button > .c-cta__button {
  display: block;
}

.c-figure {
  display: table;
  max-width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.c-figure__image-link {
  display: block;
}

.c-figure__image {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.c-figure__figcaption {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: table-caption;
  caption-side: bottom;
  margin-top: 1rem;
}

.c-figure__caption {
  font-style: italic;
}

.c-figure__sources {
  font-size: 0.75rem;
  line-height: 1.6666666667;
  margin-top: 0.75rem;
}

@media (min-width: 35em) {
  .c-figure--right {
    float: right;
    max-width: 50%;
    margin: 0 0 2rem 2rem;
  }
}

@media (min-width: 35em) {
  .c-figure--left {
    float: left;
    max-width: 50%;
    margin-top: 0;
    margin: 0 2rem 2rem 0;
  }
}

.c-form {
  position: relative;
}

.c-form__group {
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
}

.c-form__group--archer-s1e5 {
  display: none;
}

.c-form__group.is-hidden {
  display: none;
}

.c-form__step {
  display: none;
}

.c-form__step:first-child {
  display: block;
}

.c-form__submit-error,
.c-form__network-error {
  font-size: 0.875rem;
  line-height: 2;
  color: #d22323;
  background-color: #ffffff;
  position: absolute;
  z-index: 200;
}

.c-form__network-error {
  margin: 1rem;
}

.c-form__lightbox {
  content: "";
  position: absolute;
  z-index: 199;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.c-form__submit-animation {
  display: none;
}

.c-form--submitting .c-form__submit-animation {
  display: block;
  position: absolute;
  width: 5rem;
  height: 5rem;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c-form--submitting .c-form__submit-animation:after {
  content: " ";
  display: block;
  width: 4rem;
  height: 4rem;
  margin: 0.5rem;
  border-radius: 50%;
  border: 0.375rem solid #2e4f63;
  border-color: #2e4f63 transparent #2e4f63 transparent;
  animation: submit-animation 1.2s linear infinite;
}
@keyframes submit-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.c-form--submitting .c-form__lightbox {
  display: block;
}

.c-form--disabled .c-form__submit-error {
  visibility: hidden;
}
.c-form--disabled .c-form__lightbox {
  display: block;
}

.c-form__step.is-active {
  display: block;
}

.c-alert--browser-update {
  background: #bc1822;
  padding: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  flex-direction: column;
  border: none;
  border-radius: 0;
  margin: 0;
  display: none;
}
.c-alert--browser-update a {
  color: #ffffff;
}
.c-alert--browser-update p {
  font-size: 0.875rem;
  line-height: 2;
  margin: 0;
  text-align: center;
  color: #ffffff;
}

.c-site-header {
  top: auto;
}

.c-share {
  margin-top: 3rem;
  border-top: 0.0625rem solid #eeeeee;
  padding-top: 3rem;
  text-align: center;
}

.c-share__heading {
  margin-bottom: 0.5rem;
  display: block;
}

.c-share__list {
  display: flex;
  justify-content: center;
}
@media (max-width: 34.9375em) {
  .c-share__list {
    flex-direction: column;
  }
}

.c-share__item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
@media (max-width: 34.9375em) {
  .c-share__item {
    margin-bottom: 1rem;
  }
}

.c-share__link {
  width: 6rem;
  text-align: center;
}

.c-share__link--twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #ffffff;
}
.u-hover-children:hover .c-share__link--twitter, .c-share__link--twitter:hover {
  background-color: #1471a9;
  border-color: #1471a9;
  color: #ffffff;
}

.c-share__link--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #ffffff;
}
.u-hover-children:hover .c-share__link--facebook, .c-share__link--facebook:hover {
  background-color: #293e6a;
  border-color: #293e6a;
  color: #ffffff;
}

.c-share__copy {
  display: none;
}
.c-share__copy textarea {
  width: 100%;
  max-width: 30rem;
  margin: 0 auto 0.5rem;
  display: block;
  text-align: center;
}

.c-social-links__title {
  padding-bottom: 0.5rem;
}

.c-social-links__item {
  font-size: 1.25rem;
  line-height: 2;
  display: inline-block;
  margin-left: 1rem;
}
@media (min-width: 35em) {
  .c-social-links__item {
    font-size: 1.3125rem;
    line-height: 2.0952380952;
  }
}
@media (min-width: 55em) {
  .c-social-links__item {
    font-size: 1.4375rem;
    line-height: 2.0869565217;
  }
}
@media (min-width: 75em) {
  .c-social-links__item {
    font-size: 1.5625rem;
    line-height: 2.08;
  }
}
.c-social-links__item:first-child {
  margin-left: 0;
}

.c-social-links--color .o-icon--twitter {
  color: #1da1f2;
}
.c-social-links--color .o-icon--facebook {
  color: #3b5998;
}
.c-social-links--color .o-icon--google-plus {
  color: #ea4335;
}
.c-social-links--color .o-icon--linkedin {
  color: #0077b5;
}
.c-social-links--color .o-icon--pinterest {
  color: #bd081c;
}
.c-social-links--color .o-icon--instagram {
  color: #f56040;
}

.c-sources {
  margin-top: 3rem;
  border-top: 0.0625rem solid #eeeeee;
  padding-top: 3rem;
}
.c-sources__title {
  text-align: center;
  margin-bottom: 2rem;
}

.c-sources__lead-in {
  font-size: 0.75rem;
  line-height: 2;
  font-style: italic;
}

.c-sources__list-wrapper {
  height: 8rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: 0.2s ease-in-out all;
  text-align: left;
}

.c-sources__toggle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease-in-out 0s;
}
.c-sources__list-wrapper.is-active .c-sources__toggle {
  opacity: 0;
}
.c-sources__toggle:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, white 75%);
}
.c-sources__toggle .c-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.c-sources__list {
  position: relative;
  z-index: 1;
  list-style: decimal;
}
.c-sources__list li {
  font-size: 0.75rem;
  line-height: 2;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.c-sources__list a {
  color: #2e4f63;
  text-decoration: underline;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.c-sources__list a:hover {
  color: #3192b2;
  text-decoration: underline;
}

.c-wysiwyg {
  max-width: 40rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.c-wysiwyg > *:first-child {
  margin-top: 0;
}
.c-wysiwyg > *:last-child {
  margin-bottom: 0;
}
.c-wysiwyg p {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.c-wysiwyg h2 + p,
.c-wysiwyg h3 + p,
.c-wysiwyg h4 + p,
.c-wysiwyg h5 + p,
.c-wysiwyg h6 + p,
.c-wysiwyg .h2 + p,
.c-wysiwyg .h3 + p,
.c-wysiwyg .h4 + p,
.c-wysiwyg .h5 + p,
.c-wysiwyg .h6 + p {
  margin-top: 0;
}
.c-wysiwyg ul,
.c-wysiwyg ol {
  padding-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.c-wysiwyg ul {
  list-style-type: initial;
}
.c-wysiwyg ol {
  list-style-type: decimal;
}
.c-wysiwyg img,
.c-wysiwyg .wp-caption {
  max-width: 100%;
  height: auto;
  margin: 2rem auto;
  display: table;
}
@media (min-width: 35em) {
  .c-wysiwyg img,
  .c-wysiwyg .wp-caption {
    margin: 3rem auto;
  }
  .c-wysiwyg img.alignleft,
  .c-wysiwyg .wp-caption.alignleft {
    float: left;
    max-width: 50%;
    margin-right: 2rem;
  }
  .c-wysiwyg img.alignright,
  .c-wysiwyg .wp-caption.alignright {
    float: right;
    max-width: 50%;
    margin-left: 2rem;
  }
}
.c-wysiwyg img .wp-caption-text,
.c-wysiwyg .wp-caption .wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: table-caption;
  caption-side: bottom;
  margin: 0;
  text-align: center;
}
.c-wysiwyg .wp-caption img {
  margin: 0 0 1rem;
}
.c-wysiwyg .wp-caption {
  width: auto !important;
}

.c-wysiwyg--center {
  margin-left: auto;
  margin-right: auto;
}

h2 + .c-wysiwyg,
h3 + .c-wysiwyg,
h4 + .c-wysiwyg,
h5 + .c-wysiwyg,
h6 + .c-wysiwyg {
  margin-top: 0;
}

.c-blockquote {
  margin-top: 2.5rem;
  border-top: 0.0625rem solid rgb(181, 216, 227);
  padding: 2.5rem 0;
  border-bottom: 0.0625rem solid rgb(181, 216, 227);
  margin-bottom: 2.5rem;
}

.c-blockquote__content {
  text-align: left;
  position: relative;
}
@media (min-width: 35em) {
  .c-blockquote__content {
    padding-left: 4rem;
  }
}
.c-blockquote__content:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f10d";
  color: rgb(230, 242, 246);
  line-height: 1;
  display: block;
  position: absolute;
  top: -1rem;
  z-index: -1;
}
@media (max-width: 34.9375em) {
  .c-blockquote__content:before {
    font-size: 6rem;
    width: 6rem;
    height: 6rem;
    left: -1rem;
  }
}
@media (min-width: 35em) {
  .c-blockquote__content:before {
    font-size: 3rem;
    width: 3rem;
    height: 3rem;
    left: 0;
  }
}

.c-blockquote__quote {
  font-size: 1.125rem;
  line-height: 1.5555555556;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
  color: #2e4f63;
}
@media (min-width: 35em) {
  .c-blockquote__quote {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media (min-width: 55em) {
  .c-blockquote__quote {
    font-size: 1.1875rem;
    line-height: 1.4736842105;
  }
}
@media (min-width: 75em) {
  .c-blockquote__quote {
    font-size: 1.25rem;
    line-height: 1.6;
  }
}

.c-blockquote__title {
  font-size: 1rem;
  line-height: 1.5;
}

.c-blockquote__subtitle {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

.c-blockquote__source {
  color: #333333;
}

.c-action-block {
  padding: 0 2rem;
  width: 100%;
}
@media (min-width: 35em) {
  .c-action-block {
    padding: 0;
  }
}

.c-action-block--card {
  padding: 2rem;
}

.c-action-block--l {
  margin-top: 0;
  margin-bottom: 0;
  max-width: 46rem;
}
@media (max-width: 34.9375em) {
  .c-action-block--l {
    padding: 2rem 1.5rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-action-block--l {
    padding: 2rem 2.5rem;
  }
}
@media (min-width: 55em) {
  .c-action-block--l {
    padding: 3rem;
  }
}
.c-action-block--l .c-action-block__title {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: Aleo, Georgia, serif;
  font-weight: 700;
  color: #333333;
  margin-top: 4rem;
  margin-bottom: 2rem;
  margin-top: 0;
  text-align: center;
  font-weight: 400;
}
@media (min-width: 35em) {
  .c-action-block--l .c-action-block__title {
    font-size: 1.3125rem;
    line-height: 1.1428571429;
  }
}
@media (min-width: 55em) {
  .c-action-block--l .c-action-block__title {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 75em) {
  .c-action-block--l .c-action-block__title {
    font-size: 1.5625rem;
    line-height: 1.28;
  }
}
.c-action-block--l .c-action-block__copy {
  text-align: left;
}

.c-action-block__content {
  max-width: 100%;
}

.c-action-block__title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.c-action-block__image {
  margin-bottom: 1.5rem;
}

.c-action-block--dark {
  background: #333333;
  padding: 2rem;
}
.c-action-block--dark .c-action-block__title {
  color: #ffffff;
}
.c-action-block--dark .c-action-block__copy {
  color: #cccccc;
}

.c-action-block__icon {
  font-size: 4.5rem;
  line-height: 1;
  color: #3192b2;
  margin-bottom: 1.5rem;
}

.c-action-block__copy {
  margin-bottom: auto;
}
.c-action-block__copy > :first-child {
  margin-top: 0;
}
.c-action-block__copy > :last-child {
  margin-bottom: 0;
}

.c-action-block--content {
  margin-top: 0;
  margin-bottom: 0;
  max-width: 40rem;
  padding: 0;
}
.c-action-block--content .c-action-block__copy {
  text-align: left;
}

.c-profile-block--card {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 2rem;
}
.c-profile-block--card .c-profile-block__image {
  border: 0.5rem solid #cccccc;
  width: 10rem;
  height: 10rem;
}
.c-profile-block--card .c-profile-block__name {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: Aleo, Georgia, serif;
  font-weight: 700;
  color: #333333;
  margin-top: 4rem;
  margin-bottom: 2rem;
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 35em) {
  .c-profile-block--card .c-profile-block__name {
    font-size: 1.3125rem;
    line-height: 1.1428571429;
  }
}
@media (min-width: 55em) {
  .c-profile-block--card .c-profile-block__name {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 75em) {
  .c-profile-block--card .c-profile-block__name {
    font-size: 1.5625rem;
    line-height: 1.28;
  }
}
.c-profile-block--card .c-profile-block__title {
  font-size: 1rem;
  line-height: 1.5;
  color: #3192b2;
}
.c-profile-block--card .c-profile-block__after {
  font-size: 1rem;
  line-height: 2;
  text-align: left;
  margin-top: 1.5rem;
}
.c-profile-block--card .c-profile-block__after > :first-child {
  margin-top: 0;
}
.c-profile-block--card .c-profile-block__after > :last-child {
  margin-bottom: 0;
}

.c-breadcrumbs {
  margin-bottom: 1.25rem;
}

.c-cta {
  border-top: 0;
  border-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.c-cta__icon {
  font-size: 3.25rem;
  margin-bottom: 1.5rem;
}

.c-cta__copy {
  max-width: 100%;
}

.c-cta__title {
  font-size: 1.125rem;
  line-height: 1.5555555556;
}
@media (min-width: 35em) {
  .c-cta__title {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media (min-width: 55em) {
  .c-cta__title {
    font-size: 1.1875rem;
    line-height: 1.4736842105;
  }
}
@media (min-width: 75em) {
  .c-cta__title {
    font-size: 1.25rem;
    line-height: 1.6;
  }
}

.c-cta__description {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin-bottom: 0;
}

.c-cta__button {
  margin-top: 1.5rem;
}

.c-cta--light {
  background-color: #2e4f63;
  padding: 1.25rem;
  border-top: 0.35rem solid #3cba8c;
  border-bottom: 0.35rem solid #3cba8c;
}
.c-cta--light .c-cta__description {
  color: #ffffff;
}
.c-cta--light .c-cta__icon {
  color: #ffffff;
}
.c-cta--light .c-cta__title {
  color: #ffffff;
  font-weight: bold;
}
.c-cta--light .c-button {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  border-radius: 1.5rem;
  border-width: 0.125rem;
  padding: 0.875rem 1.5rem;
  letter-spacing: rem(1);
  font-weight: bold;
  color: #2e4f63;
}
.u-hover-children:hover .c-cta--light .c-button, .c-cta--light .c-button:hover {
  background-color: #b3b3b3;
  border-color: #b3b3b3;
  color: #333333;
}

.c-cta--footer {
  margin: 0;
  max-width: 100%;
  background-color: #2e4f63;
  color: #ffffff;
}
@media (max-width: 54.9375em) {
  .c-cta--footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media (min-width: 55em) {
  .c-cta--footer {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.c-cta--footer .c-cta__title {
  font-size: 1.25rem;
  line-height: 1.6;
}
@media (min-width: 35em) {
  .c-cta--footer .c-cta__title {
    font-size: 1.3125rem;
    line-height: 1.5238095238;
  }
}
@media (min-width: 55em) {
  .c-cta--footer .c-cta__title {
    font-size: 1.4375rem;
    line-height: 1.5652173913;
  }
}
@media (min-width: 75em) {
  .c-cta--footer .c-cta__title {
    font-size: 1.5625rem;
    line-height: 1.44;
  }
}
@media (max-width: 54.9375em) {
  .c-section--overlap + .c-cta--footer {
    padding-top: 5.5rem;
  }
}
@media (min-width: 55em) {
  .c-section--overlap + .c-cta--footer {
    padding-top: 9rem;
  }
}
.c-cta--footer .c-button {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;
}
.u-hover-children:hover .c-cta--footer .c-button, .c-cta--footer .c-button:hover {
  background-color: #d22323;
  border-color: #d22323;
  color: #ffffff;
}

.c-cta--inline {
  border-top: 0.35rem solid #3cba8c;
  border-bottom: 0.35rem solid #3cba8c;
  background-color: #2e4f63;
  padding: 2.5rem 1.5rem;
}
.c-cta--inline .c-cta__title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  display: block;
  color: #ffffff;
  font-weight: 700;
  margin: 0;
}
@media (min-width: 35em) {
  .c-cta--inline .c-cta__title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 55em) {
  .c-cta--inline .c-cta__title {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 75em) {
  .c-cta--inline .c-cta__title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
.c-cta--inline .c-cta__description {
  font-size: 1rem;
  line-height: 1.5;
}
.c-cta--inline .c-button {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  border-radius: 1.5rem;
  border-width: 0.125rem;
  padding: 0.875rem 1.5rem;
  letter-spacing: rem(1);
  font-weight: bold;
  color: #2e4f63;
}
.u-hover-children:hover .c-cta--inline .c-button, .c-cta--inline .c-button:hover {
  background-color: #b3b3b3;
  border-color: #b3b3b3;
  color: #333333;
}
@media (min-width: 35em) {
  .c-cta--inline {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .c-cta--inline .c-cta__copy {
    margin-right: 1.5rem;
  }
  .c-cta--inline .c-cta__title,
  .c-cta--inline .c-cta__description {
    text-align: left;
  }
  .c-cta--inline .c-cta__description {
    margin-top: 0.25rem;
  }
  .c-cta--inline .c-cta__button {
    flex-shrink: 0;
    margin-top: 0;
  }
}

.c-factbox {
  margin-top: 2rem;
  border-top: 0.0625rem solid rgb(181, 216, 227);
  padding: 1.5rem;
  border-bottom: 0.0625rem solid rgb(181, 216, 227);
  margin-bottom: 2rem;
}

.c-factbox__title {
  font-size: 1.125rem;
  line-height: 2;
  color: #2e4f63;
  font-family: "Aleo", "Georgia", serif;
}
@media (min-width: 35em) {
  .c-factbox__title {
    font-size: 1.125rem;
    line-height: 2;
  }
}
@media (min-width: 55em) {
  .c-factbox__title {
    font-size: 1.1875rem;
    line-height: 2.1052631579;
  }
}
@media (min-width: 75em) {
  .c-factbox__title {
    font-size: 1.25rem;
    line-height: 2;
  }
}

.c-factbox__copy {
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(42, 123, 150);
}

.c-factbox__source {
  font-size: 0.75rem;
  line-height: 2;
}

.c-pro-con__lists {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}
@media (min-width: 35em) {
  .c-pro-con__lists {
    flex-wrap: nowrap;
  }
}

.c-pro-con__list {
  width: 100%;
  padding: 1.5rem;
}
.c-pro-con__list > :first-child {
  margin-top: 0;
}
.c-pro-con__list > *:last-child {
  margin-bottom: 0;
}

.c-pro-con__list--cons {
  background-color: rgb(250, 229, 229);
}

.c-pro-con__list--pros {
  background-color: rgb(232, 247, 241);
}

.c-pro-con__sub-title {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Aleo, Georgia, serif;
  font-weight: 700;
  color: #333333;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  padding-left: 0;
  border-left: 0;
}
.c-pro-con__list--pros .c-pro-con__sub-title {
  color: rgb(28, 67, 50);
}
.c-pro-con__list--cons .c-pro-con__sub-title {
  color: rgb(99, 13, 13);
}
.c-wysiwyg .c-pro-con__sub-title {
  padding: 0;
  border: 0;
}

.c-wysiwyg .c-ul {
  padding-left: 0;
  margin-top: 0;
}
.c-wysiwyg .c-ul li {
  font-size: 1rem;
  line-height: 1.5;
  padding-left: 2rem;
}
.c-wysiwyg .c-ul li:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  line-height: 1.5;
  top: 0;
  left: 0;
}
.c-wysiwyg .c-ul--pro li {
  color: rgb(28, 67, 50);
}
.c-wysiwyg .c-ul--pro li:before {
  content: "\f00c";
  color: #3cba8c;
}
.c-wysiwyg .c-ul--con li {
  color: rgb(99, 13, 13);
}
.c-wysiwyg .c-ul--con li:before {
  content: "\f00d";
  color: #d22323;
}

.c-dl__definition {
  padding-left: 0;
}

.c-dl__group {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  padding-top: 0.75rem;
  border-bottom: 0.0625rem solid #eeeeee;
}

.c-dl__title {
  font-family: "Aleo", "Georgia", serif;
}

.c-share {
  width: 100%;
}

.c-share__link {
  width: auto;
}

.c-share__copy textarea {
  margin-top: 0.5rem;
}

.c-sources__list a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.c-wysiwyg h2:after,
.c-wysiwyg .h2:after {
  margin-top: 1rem;
  width: 6rem;
  height: 0.125rem;
  background: #3192b2;
  content: "";
  display: block;
}
.c-wysiwyg h3,
.c-wysiwyg .h3 {
  padding-left: 1.5rem;
  border-left: 0.125rem solid #cccccc;
}
.c-wysiwyg ol {
  list-style-type: none;
  counter-reset: section;
  margin: 1.25rem 0 1.25rem 2.5rem;
  padding-left: 0;
}
.c-wysiwyg ol li {
  counter-increment: section;
  padding-left: 1.25rem;
  margin-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}
.c-wysiwyg ol li:last-child {
  margin-bottom: 0;
}
.c-wysiwyg ol li:before {
  color: #3192b2;
  content: counters(section, "");
  border: 0.125rem solid #3192b2;
  border-radius: 50%;
  display: inline-block;
  float: left;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.25rem;
  text-align: center;
  font-weight: 700;
  margin: -0.25rem 0 0 -3.75rem;
}
.c-wysiwyg ul {
  list-style-type: none;
}
.c-wysiwyg ul li {
  position: relative;
}
.c-wysiwyg ul li:before {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  content: "•";
  color: #3192b2;
  position: absolute;
  top: 0.25rem;
  left: -1.25rem;
}
@media (min-width: 35em) {
  .c-wysiwyg ul li:before {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
@media (min-width: 55em) {
  .c-wysiwyg ul li:before {
    font-size: 1.1875rem;
    line-height: 1.0526315789;
  }
}
@media (min-width: 75em) {
  .c-wysiwyg ul li:before {
    font-size: 1.25rem;
    line-height: 1;
  }
}
.c-action-list__items {
  margin-right: -1rem;
  margin-left: -1rem;
}
@media (min-width: 55em) {
  .c-action-list__items {
    display: flex;
    justify-content: center;
  }
}

.c-action-list__item {
  padding-right: 1rem;
  padding-left: 1rem;
}

/* Modifiers */
.c-action-list--wrap .c-action-list__items {
  flex-wrap: wrap;
}
.c-action-list--wrap .c-action-list__item {
  flex: 1 0 auto;
}

.c-action-list--2-col .c-action-list__items {
  flex-wrap: wrap;
}
@media (min-width: 55em) {
  .c-action-list--2-col .c-action-list__item {
    flex: 0 1 50%;
  }
}
.c-action-list--2-col img {
  max-width: 100%;
}

.c-action-list--3-col .c-action-list__items {
  flex-wrap: wrap;
}
@media (min-width: 55em) {
  .c-action-list--3-col .c-action-list__item {
    flex: 0 1 33%;
  }
}
.c-action-list--3-col img {
  max-width: 100%;
}

.c-action-list--4-col .c-action-list__items {
  flex-wrap: wrap;
}
@media (min-width: 55em) {
  .c-action-list--4-col .c-action-list__item {
    flex: 0 1 25%;
  }
}
.c-action-list--4-col img {
  max-width: 100%;
}

.c-action-list--5-col .c-action-list__items {
  flex-wrap: wrap;
}
@media (min-width: 55em) {
  .c-action-list--5-col .c-action-list__item {
    flex: 0 1 20%;
  }
}
.c-action-list--5-col img {
  max-width: 100%;
}

.c-action-list--6-col .c-action-list__items {
  flex-wrap: wrap;
}
@media (min-width: 55em) {
  .c-action-list--6-col .c-action-list__item {
    flex: 0 1 16.66%;
  }
}
.c-action-list--6-col img {
  max-width: 100%;
}

.c-attribution {
  margin-top: 1rem;
}

.c-attribution--footer {
  margin-top: 3rem;
  border-top: 0.0625rem solid #eeeeee;
  padding-top: 3rem;
}
.c-attribution--footer .c-attribution__additional {
  display: flex;
}
.c-attribution--footer .c-attribution__profile {
  flex-grow: 1;
  flex-shrink: 1;
}

.c-attribution--inline {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}
.c-page--two-column .c-attribution--inline {
  margin-top: 0;
}
@media (min-width: 35em) {
  .c-attribution--inline .c-attribution__item {
    display: flex;
    align-items: flex-start;
  }
}
.c-attribution--inline .c-attribution__image {
  float: left;
  margin-right: 1rem;
}
@media (min-width: 35em) {
  .c-attribution--inline .c-attribution__image {
    float: none;
    flex: 0 0 auto;
  }
}
.c-attribution--inline .c-attribution__image img {
  border-radius: 50%;
}
.c-attribution--inline .c-attribution__buttons .c-button {
  margin-right: 0.75rem;
  margin-top: 0.5rem;
  font-size: 0.625rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}
@media (min-width: 35em) {
  .c-attribution--inline .c-attribution__buttons .c-button {
    margin-right: 1rem;
  }
}
.c-attribution--inline .c-attribution__expert-modal-link {
  margin-top: 0.5rem;
  line-height: 1;
}
.c-attribution--inline .c-attribution__copy ul {
  padding-top: 1rem;
}
@media (min-width: 35em) {
  .c-attribution--inline .c-attribution__copy ul {
    padding-top: 0;
  }
  .c-attribution--inline .c-attribution__copy ul li {
    display: inline;
    padding-right: 0.5rem;
  }
  .c-attribution--inline .c-attribution__copy ul li:before {
    content: "•";
    margin-right: 0.5rem;
  }
  .c-attribution--inline .c-attribution__copy ul li:first-child:before {
    content: "";
    margin-right: 0;
  }
}

.c-hero__before {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.c-hero--single .c-breadcrumbs {
  margin-bottom: 1rem;
}
.c-hero--single .c-hero__content {
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.c-media-block {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 55em) {
  .c-media-block {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
    justify-content: space-between;
    align-items: center;
  }
}

.c-media-block__media {
  text-align: center;
}
@media (max-width: 34.9375em) {
  .c-media-block__media {
    margin-bottom: 2rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-media-block__media {
    margin-bottom: 3rem;
  }
}
@media (min-width: 55em) {
  .c-media-block__media {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 50%;
  }
}

.c-media-block__image {
  position: relative;
  display: inline-block;
}
.c-media-block__image .o-image {
  max-width: 100%;
  z-index: 1;
  position: relative;
}

@media (min-width: 55em) {
  .c-media-block__content {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 50%;
  }
  .c-media-block__content .c-wysiwyg {
    max-width: 100%;
  }
}
.c-media-block__content .c-button {
  margin-right: 1rem;
}

@media (min-width: 55em) {
  .c-media-block--media-right .c-media-block__media {
    order: 2;
  }
}

@media (min-width: 55em) {
  .c-media-block--media-smaller .c-media-block__content {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .c-media-block--media-smaller .c-media-block__media {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

@media (min-width: 55em) {
  .c-media-block--third .c-media-block__content {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .c-media-block--third .c-media-block__media {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.c-expand-menu__list--d1 {
  padding-left: 0;
}

.c-expand-menu__list--d2 {
  display: none;
  width: 100%;
  padding: 0.75rem 0;
}
.c-expand-menu__item--has-children.is-active .c-expand-menu__list--d2 {
  display: block;
}

.c-expand-menu__item {
  font-size: 1rem;
  line-height: 1.75;
}

.c-expand-menu__item--d1 {
  margin-bottom: 1rem;
}

.c-expand-menu__item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.c-expand-menu__item-wrapper--d1 {
  background-color: #eeeeee;
  color: #2e4f63;
}

.c-expand-menu__link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: inline-block;
  color: #2e4f63;
  flex-grow: 1;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.c-expand-menu__link:hover {
  text-decoration: underline;
  color: #2e4f63;
}
.c-expand-menu__link.c-expand-menu__link--current {
  font-weight: bold;
  color: #2e4f63;
}

.c-expand-menu__link--d1 {
  font-size: 0.875rem;
  line-height: 2;
  border-left: 0.125rem solid #3192b2;
  padding: 1rem;
}

.c-expand-menu__link--d2 {
  color: #2e4f63;
  padding: 0.5rem 1rem;
}

.c-expand-menu__toggle {
  background-color: transparent;
  border-color: transparent;
  color: #3cba8c;
  width: 3.75rem;
  height: 3.75rem;
  line-height: 3.75rem;
  padding: 0;
  border: 0;
  display: block;
}
.u-hover-children:hover .c-expand-menu__toggle, .c-expand-menu__toggle:hover {
  background-color: transparent;
  border-color: transparent;
  color: #333333;
}

.c-modal {
  background-color: #ffffff;
  padding: 2rem 1.5rem;
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-modal {
    padding: 2rem;
  }
}
@media (min-width: 55em) {
  .c-modal {
    padding: 2.5rem;
  }
}

.c-modal__title {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: Aleo, Georgia, serif;
  font-weight: 700;
  color: #333333;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
@media (min-width: 35em) {
  .c-modal__title {
    font-size: 1.3125rem;
    line-height: 1.1428571429;
  }
}
@media (min-width: 55em) {
  .c-modal__title {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 75em) {
  .c-modal__title {
    font-size: 1.5625rem;
    line-height: 1.28;
  }
}

.c-popover {
  position: relative;
  display: inline-block;
}

.c-popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -6.25rem;
  right: -6.25rem;
  top: 3rem;
  background-color: #eeeeee;
  padding: 1.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  width: auto;
  max-width: 90vw;
}

.c-popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #eeeeee transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.c-popover:hover .c-popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.c-popover__title {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
}

.c-popover__jobtitle {
  margin-top: 0;
  font-style: italic;
  font-size: 0.9375rem;
  line-height: 1.6;
}

.c-popover__excerpt {
  font-size: 0.9375rem;
  line-height: 1.6;
}

.c-popover__content img {
  border-radius: 50%;
}

.c-popover__content .c-button--link {
  padding: 0;
}

.c-mobile-nav {
  position: relative;
  z-index: 900;
}

.c-mobile-nav__drawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #ffffff;
  width: 90%;
  max-width: 25rem;
  height: 100%;
  color: #333333;
  padding: 1rem;
  box-shadow: 0 0.0625rem 0.0625rem 0 rgba(51, 51, 51, 0.3);
  transition: all 0.2s ease-in-out;
  transform: translateX(-100%);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 34.9375em) {
  .c-mobile-nav__drawer {
    padding-top: 5rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-mobile-nav__drawer {
    padding-top: 6rem;
  }
}
@media (min-width: 55em) {
  .c-mobile-nav__drawer {
    display: none;
  }
}
.c-mobile-nav.is-active .c-mobile-nav__drawer {
  transform: translateX(0);
}

.c-mobile-nav__mask {
  position: fixed;
  cursor: pointer;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease-in-out, visibility 0s ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 55em) {
  .c-mobile-nav__mask {
    width: 0;
    height: 0;
  }
}
.c-mobile-nav.is-active .c-mobile-nav__mask {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease-in-out, visibility 0s ease-in-out 0s;
}

.c-mobile-nav__title {
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 1.5rem;
  font-family: "Aleo", "Georgia", serif;
  font-weight: 400;
  font-style: italic;
  display: block;
}
.c-mobile-nav__title:after {
  margin: 0.5rem 0 0;
  content: "";
  height: 0.125rem;
  width: 4rem;
  background: #2e4f63;
  display: block;
}

.c-related-posts {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.c-related-posts__title {
  margin-bottom: 2rem;
}

.c-related-posts__list {
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;
  overflow-x: auto;
}

.c-related-posts__item {
  padding: 0 1rem;
  min-width: 18rem;
  display: flex;
  align-items: stretch;
}
.c-related-posts__item .c-post-block {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}
.c-related-posts__item .c-post-block--card,
.c-related-posts__item .c-post-block--card .c-post-block__inner,
.c-related-posts__item .c-post-block--card .c-post-block__content {
  display: flex;
  align-items: stretch;
}
.c-related-posts__item .c-post-block--card .c-post-block__inner,
.c-related-posts__item .c-post-block--card .c-post-block__content {
  flex-direction: column;
}
.c-related-posts__item .c-post-block--card .c-post-block__content {
  flex-grow: 1;
}
.c-related-posts__item .c-post-block--card .c-post-block__after {
  margin-top: auto;
}

.c-related-profiles {
  margin-top: 3rem;
}

.c-related-profiles__heading {
  margin-bottom: 2rem;
  text-align: center;
}

.c-related-profiles__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.c-related-profiles__list li {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 50%;
  margin-bottom: 2rem;
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-related-profiles__list li {
    width: 33.3333333333%;
    margin-bottom: 3rem;
  }
}
@media (min-width: 55em) {
  .c-related-profiles__list li {
    width: 25%;
    margin-bottom: 3rem;
  }
}
.c-related-profiles__list .c-profile-block {
  display: flex;
}

.c-related-profiles--card .c-related-profiles__list {
  margin-left: auto;
  margin-right: auto;
}
.c-related-profiles--card li {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  transform: translateY(0);
  transition: all 0.2s ease-in-out;
  flex: 0 0 46%;
  margin: 2%;
  width: auto;
  padding: 1rem;
}
.c-related-profiles--card li:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.375rem 0.5rem 0.25rem rgba(0, 0, 0, 0.2);
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-related-profiles--card li {
    flex: 0 0 29.33%;
  }
}
@media (min-width: 55em) {
  .c-related-profiles--card li {
    flex: 0 0 21%;
  }
}

.c-related-profiles--two-col li {
  flex: 0 1 96%;
}
@media (min-width: 35em) {
  .c-related-profiles--two-col li {
    flex: 0 1 46%;
  }
}

@media (min-width: 35em) {
  .c-related-profiles--three-col li {
    flex: 0 1 29.33%;
  }
}

.c-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  overflow-x: hidden;
}
@media (min-width: 35em) {
  .c-section {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
}
@media (min-width: 55em) {
  .c-section {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.c-section__description {
  margin-top: 1.5rem;
  margin-bottom: 0rem;
}

.c-section--light {
  background-color: #eeeeee;
}

.c-section--center .c-section__header,
.c-section--center .c-section__footer {
  text-align: center;
}
.c-section--center .c-wysiwyg {
  margin-left: auto;
  margin-right: auto;
}

.c-widget {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}
.c-widget:last-child {
  margin-bottom: 0;
}

.c-widget__title {
  margin-bottom: 1rem;
}

.c-widget--sticky {
  position: sticky;
  top: 2rem;
}

@media (max-width: 54.9375em) {
  .c-action-list__item {
    max-width: 32rem;
    margin-left: auto;
    margin-right: auto;
  }
  .c-action-list__item:first-child .c-action-block {
    margin-top: 0;
  }
  .c-action-list__item:last-child .c-action-block {
    margin-bottom: 0;
  }
}
@media (min-width: 55em) {
  .c-action-list__item {
    display: flex;
    align-items: stretch;
    flex-basis: 0;
    flex-grow: 1;
  }
  .c-action-list__item .c-action-block {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.c-action-list--auto .c-action-list__items {
  flex-wrap: wrap;
}
.c-action-list--auto .c-action-list__item {
  flex: 0 1 auto;
}

.c-action-list--profile {
  padding: 1.5rem 0;
}
@media (min-width: 55em) {
  .c-action-list--profile .c-action-list__item {
    flex: 0 1 22rem;
  }
}
@media (min-width: 55em) {
  .c-action-list--profile .c-action-block {
    width: 18rem;
  }
}
.c-action-list--profile .c-action-block__subtitle {
  font-size: 1.125rem;
  line-height: 1.5555555556;
  font-family: "Aleo", "Georgia", serif;
  font-weight: bold;
  color: #3cba8c;
}
@media (min-width: 35em) {
  .c-action-list--profile .c-action-block__subtitle {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media (min-width: 55em) {
  .c-action-list--profile .c-action-block__subtitle {
    font-size: 1.1875rem;
    line-height: 1.4736842105;
  }
}
@media (min-width: 75em) {
  .c-action-list--profile .c-action-block__subtitle {
    font-size: 1.25rem;
    line-height: 1.6;
  }
}
.c-action-list--profile .c-action-block__title {
  font-weight: normal;
}
.c-action-list--profile .c-action-block__image img {
  border-radius: 50%;
}

.c-attribution--footer {
  padding-top: 3rem;
  text-align: center;
}
@media (min-width: 35em) {
  .c-attribution--footer {
    text-align: left;
  }
}
.c-attribution--footer .c-profile-block__header {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
@media (max-width: 34.9375em) {
  .c-attribution--footer .c-profile-block__header {
    flex-wrap: wrap;
    gap: 1rem;
  }
}
.c-attribution--footer .c-attribution__author,
.c-attribution--footer .c-attribution__editor,
.c-attribution--footer .c-attribution__reviewer {
  display: inline-block;
  flex-wrap: wrap;
  margin: 0 auto 2rem;
}
@media (min-width: 35em) {
  .c-attribution--footer .c-attribution__author,
  .c-attribution--footer .c-attribution__editor,
  .c-attribution--footer .c-attribution__reviewer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.c-attribution--footer .c-attribution__author .c-profile-block,
.c-attribution--footer .c-attribution__editor .c-profile-block,
.c-attribution--footer .c-attribution__reviewer .c-profile-block {
  align-items: center;
  display: block;
  flex-wrap: wrap;
  width: 100%;
}
@media (min-width: 35em) {
  .c-attribution--footer .c-attribution__author .c-profile-block,
  .c-attribution--footer .c-attribution__editor .c-profile-block,
  .c-attribution--footer .c-attribution__reviewer .c-profile-block {
    display: inline-flex;
  }
}
@media (max-width: 34.9375em) {
  .c-attribution--footer .c-attribution__author .c-profile-block__image-wrapper,
  .c-attribution--footer .c-attribution__editor .c-profile-block__image-wrapper,
  .c-attribution--footer .c-attribution__reviewer .c-profile-block__image-wrapper {
    flex-basis: 100%;
  }
}
.c-attribution--footer .c-attribution__author .c-profile-block__image,
.c-attribution--footer .c-attribution__editor .c-profile-block__image,
.c-attribution--footer .c-attribution__reviewer .c-profile-block__image {
  margin: 0;
}
@media (min-width: 35em) {
  .c-attribution--footer .c-attribution__author .c-profile-block__image,
  .c-attribution--footer .c-attribution__editor .c-profile-block__image,
  .c-attribution--footer .c-attribution__reviewer .c-profile-block__image {
    margin-right: 2rem;
  }
}
.c-attribution--footer .c-attribution__author .c-profile-block__content,
.c-attribution--footer .c-attribution__editor .c-profile-block__content,
.c-attribution--footer .c-attribution__reviewer .c-profile-block__content {
  margin: 1rem 0;
  width: 100%;
}
.c-attribution--footer .c-attribution__author .c-profile-block__content .c-profile-block__before,
.c-attribution--footer .c-attribution__editor .c-profile-block__content .c-profile-block__before,
.c-attribution--footer .c-attribution__reviewer .c-profile-block__content .c-profile-block__before {
  margin-bottom: 0;
}
@media (min-width: 55em) {
  .c-attribution--footer .c-attribution__author .c-profile-block__content,
  .c-attribution--footer .c-attribution__editor .c-profile-block__content,
  .c-attribution--footer .c-attribution__reviewer .c-profile-block__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
  }
}
@media (max-width: 54.9375em) {
  .c-attribution--footer .c-attribution__author .c-profile-block__content,
  .c-attribution--footer .c-attribution__editor .c-profile-block__content,
  .c-attribution--footer .c-attribution__reviewer .c-profile-block__content {
    flex-direction: column;
  }
}
@media (max-width: 34.9375em) {
  .c-attribution--footer .c-attribution__author .c-profile-block__content,
  .c-attribution--footer .c-attribution__editor .c-profile-block__content,
  .c-attribution--footer .c-attribution__reviewer .c-profile-block__content {
    display: block;
    margin: 0 auto;
  }
}
.c-attribution--footer .c-attribution__author .c-profile-block__content-inner,
.c-attribution--footer .c-attribution__editor .c-profile-block__content-inner,
.c-attribution--footer .c-attribution__reviewer .c-profile-block__content-inner {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: auto;
}
.c-attribution--footer .c-attribution__author .c-profile-block__before,
.c-attribution--footer .c-attribution__editor .c-profile-block__before,
.c-attribution--footer .c-attribution__reviewer .c-profile-block__before {
  font-size: 1rem;
  line-height: 2;
  color: #333333;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  width: 100%;
}
.c-attribution--footer .c-attribution__author .c-profile-block__name,
.c-attribution--footer .c-attribution__editor .c-profile-block__name,
.c-attribution--footer .c-attribution__reviewer .c-profile-block__name {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.25rem;
  text-decoration: underline;
}
.c-attribution--footer .c-attribution__author .c-profile-block__name:hover,
.c-attribution--footer .c-attribution__editor .c-profile-block__name:hover,
.c-attribution--footer .c-attribution__reviewer .c-profile-block__name:hover {
  color: #30936f;
}
.c-attribution--footer .c-attribution__author .c-profile-block__title,
.c-attribution--footer .c-attribution__editor .c-profile-block__title,
.c-attribution--footer .c-attribution__reviewer .c-profile-block__title {
  align-items: center;
  display: block;
  font-size: 1rem;
  font-style: italic;
  line-height: 1.25;
  /*@include media-breakpoint-up(m) {
      display: block;
  }*/
}
@media (max-width: 34.9375em) {
  .c-attribution--footer .c-attribution__author .c-profile-block__title,
  .c-attribution--footer .c-attribution__editor .c-profile-block__title,
  .c-attribution--footer .c-attribution__reviewer .c-profile-block__title {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}
.c-attribution--footer .c-attribution__author .c-profile-block__bio-button,
.c-attribution--footer .c-attribution__editor .c-profile-block__bio-button,
.c-attribution--footer .c-attribution__reviewer .c-profile-block__bio-button {
  margin-left: auto;
  min-width: 10rem;
  text-align: right;
}
@media (max-width: 54.9375em) {
  .c-attribution--footer .c-attribution__author .c-profile-block__bio-button,
  .c-attribution--footer .c-attribution__editor .c-profile-block__bio-button,
  .c-attribution--footer .c-attribution__reviewer .c-profile-block__bio-button {
    margin-right: auto;
    text-align: center;
  }
}
.c-attribution--footer .c-attribution__author {
  border: 0.25rem solid #eeeeee;
  border-radius: 0.9375rem;
  padding: 1.5rem 2rem;
  flex-wrap: wrap;
}
.c-attribution--footer .c-attribution__author .c-profile-block__extended-excerpt {
  margin-top: 1.5rem;
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-attribution--footer .c-attribution__author .c-profile-block__extended-excerpt {
    margin-top: 0.5rem;
  }
}
@media (max-width: 34.9375em) {
  .c-attribution--footer .c-attribution__author .c-profile-block__extended-excerpt {
    text-align: left;
  }
}
.c-attribution--footer .c-attribution__author .c-profile-block__intro {
  line-height: 1.75;
  text-align: left;
  width: 100%;
}
.c-attribution--footer .c-attribution__author .c-profile-block__intro ul {
  margin-top: 1.5rem;
}
.c-attribution--footer .c-attribution__author .c-profile-block__intro li {
  padding-left: 2rem;
  position: relative;
  margin-top: 1rem;
}
.c-attribution--footer .c-attribution__author .c-profile-block__intro li:before {
  border-right: 0.125rem solid #333333;
  border-top: 0.125rem solid #333333;
  content: "";
  display: block;
  height: 0.4rem;
  left: 0.35rem;
  position: absolute;
  top: 0.65rem;
  transform: rotate(45deg);
  width: 0.4rem;
  z-index: 5;
}
.c-attribution--footer .c-attribution__author .c-profile-block__intro li:after {
  background: none;
  border: 0.125rem solid #333333;
  border-radius: 0.1875rem;
  content: "";
  display: block;
  height: 1.25rem;
  left: 0;
  position: absolute;
  top: 0.25rem;
  width: 1.25rem;
}
@media (max-width: 54.9375em) {
  .c-attribution--footer .c-attribution__author .c-profile-block__intro {
    display: inline-block;
  }
}
@media (max-width: 34.9375em) {
  .c-attribution--footer .c-attribution__author {
    padding: 1.25rem 2.5rem;
  }
}
.c-attribution--footer .c-profile-block__content-email-button {
  padding-left: 1rem;
}
@media (max-width: 54.9375em) {
  .c-attribution--footer .c-profile-block__content-email-button {
    padding-left: 0;
  }
}
.c-attribution--footer .c-profile-block__email {
  background: #eaf2fd;
  border-radius: 0.3125rem;
  display: block;
  margin-left: auto;
  margin-top: 0.25rem;
  padding: 0.125rem 0.75rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}
.c-attribution--footer .c-profile-block__email:hover {
  text-decoration: underline;
}
.c-attribution--footer .c-profile-block__email:before {
  color: #3cba8c;
  margin-right: 0;
}
@media (max-width: 54.9375em) {
  .c-attribution--footer .c-profile-block__email {
    display: inline-block;
    margin-top: 0.5rem;
  }
}
@media (max-width: 34.9375em) {
  .c-attribution--footer .c-profile-block__email {
    margin: 1rem auto 0 auto;
    padding: 0.4rem 1rem;
  }
}
.c-attribution--footer .c-profile-block__email-label {
  margin-left: 0.5rem;
  text-transform: none;
}
.c-attribution--footer .c-attribution__bio-link {
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: 1.5rem;
  padding-right: 1.75rem;
  position: relative;
}
.c-attribution--footer .c-attribution__bio-link:after {
  content: "➞";
  position: absolute;
  right: 0;
}
.c-attribution--footer .c-attribution__button {
  flex: 0 0 auto;
}
.c-attribution--footer .c-attribution__additional {
  flex-direction: column;
}
@media (min-width: 35em) {
  .c-attribution--footer .c-attribution__additional {
    display: block;
    padding: 0 2.25rem;
  }
}
@media (max-width: 54.9375em) {
  .c-attribution--footer .c-attribution__additional {
    flex-direction: column;
  }
}
.c-attribution--footer .c-attribution__additional > div:last-child {
  margin-bottom: 0;
}

.c-attribution__hero--hub {
  margin-top: 3rem;
  margin-bottom: 0;
}

.c-attribution--inline {
  border-top: 0.125rem solid #eeeeee;
  border-bottom: 0.125rem solid #eeeeee;
  padding: 1.5rem 0;
  font-size: 0.875rem;
  /*.c-page--one-column & {
      @include media-breakpoint-only(s) {
          padding-left: $spacer;
          padding-right: $spacer;
      }
  }*/
}
.c-hero--backpage .c-attribution--inline {
  margin: 2rem auto;
  padding-top: 2rem;
  border-top: 0.125rem solid #eeeeee;
}
.c-hero--single .c-attribution--inline {
  margin-top: 1.5rem;
}
.c-attribution--inline .c-attribution__buttons .c-button {
  border-width: 0.1rem;
  font-weight: 700;
  padding: 0.25rem 0.5rem;
}
.c-attribution--inline .c-attribution__copy ul {
  line-height: 2;
}
@media (min-width: 35em) {
  .c-attribution--inline .c-attribution__copy ul li:before {
    color: #3cba8c;
  }
}
@media (max-width: 54.9375em) {
  .c-attribution--inline .c-popover {
    position: static;
  }
}
@media (max-width: 54.9375em) {
  .c-attribution--inline .c-popover__content {
    left: 1rem;
    right: 1rem;
  }
}
@media (max-width: 54.9375em) {
  .c-attribution--inline .c-popover__content:before {
    display: none;
  }
}
.c-page__gutenberg .c-attribution--inline {
  max-width: 60rem;
}

/*.c-page--one-column {
    .c-attribution__additional {
        .c-profile-block__content {
            flex-direction: column;
        }
        .c-profile-block__content-email-button {
            padding-left: 0;
        }
    }

    @include media-breakpoint-up(m) {
        .c-attribution--footer {
            display: flex;
            max-width: 60rem;
        }
    }
    @include media-breakpoint-down(m) {
        .c-attribution--footer {
            flex-direction: column; 

            .c-profile-block__email {
                margin-top: .5rem;
            }
        }
    }
}*/
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.c-experts .c-profile-block {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media (min-width: 35em) {
  .c-experts .c-profile-block {
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: row;
    text-align: left;
  }
}
.c-experts .c-profile-block__image {
  border-radius: 0;
  margin: 0 0 1rem;
}
@media (min-width: 35em) {
  .c-experts .c-profile-block__image {
    margin: 0 1.5rem 0 0;
  }
}
.c-experts .c-profile-block__content {
  color: #333333;
}
.c-experts .c-profile-block__name {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
@media (min-width: 35em) {
  .c-experts .c-profile-block__name {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 55em) {
  .c-experts .c-profile-block__name {
    font-size: 1.1875rem;
    line-height: 1.4736842105;
  }
}
@media (min-width: 75em) {
  .c-experts .c-profile-block__name {
    font-size: 1.25rem;
    line-height: 1.4;
  }
}
.c-experts .c-profile-block__title {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.c-experts .c-profile-block__after {
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 0.75rem;
}

.c-experts--footer {
  border-top: 0.0625rem solid #eeeeee;
  margin-top: 2rem;
}
@media (min-width: 35em) {
  .c-experts--footer {
    margin-top: 4rem;
  }
}
.c-experts--footer .h2 {
  text-align: center;
}
.c-experts--footer .h2:after {
  display: none;
}

.c-page--one-column .c-content__after > .c-experts--footer {
  max-width: 64rem;
}

.c-hero__expert-modal-link {
  padding: 0 1rem;
}

.c-experts__modal {
  text-align: center;
  border-radius: 1rem;
}
.c-experts__modal.c-modal {
  padding-bottom: 0;
}
.c-experts__modal h3 {
  margin-bottom: 2rem;
}

.contributor-modal__contributor {
  display: block;
  border-bottom: thin solid #b3b3b3;
  max-width: 80%;
  margin: 0 auto;
  padding: 0.8rem 0;
}

.contributor-modal__read-more {
  font-size: 0.875rem;
  line-height: 2;
  padding: 2rem;
  font-style: italic;
}

.c-site-header {
  color: #ffffff;
  background-color: transparent;
  position: fixed;
  top: auto;
  width: 100%;
  min-width: 20rem;
  z-index: 1000;
  box-shadow: 0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0);
  transform: translateY(0%);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.3s transform ease-in-out;
}
.c-site-header > .l-container {
  max-width: 80rem;
}
.has-scrolled .c-site-header, .c-site-header.is-active {
  background-color: #2e4f63;
  box-shadow: 0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.3);
}
.hide-site-header .c-site-header {
  transform: translateY(-110%);
}

.c-site-header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 34.9375em) {
  .c-site-header__content {
    height: 3rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-header__content {
    height: 4rem;
  }
}
@media (min-width: 55em) {
  .c-site-header__content {
    height: 5rem;
  }
}

.c-site-header__button {
  background-color: transparent;
  border-color: transparent;
  color: #ffffff;
  font-size: 1.25rem;
  line-height: 2;
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 0;
}
.u-hover-children:hover .c-site-header__button, .c-site-header__button:hover {
  background-color: transparent;
  border-color: transparent;
  color: #ffffff;
}
@media (min-width: 35em) {
  .c-site-header__button {
    font-size: 1.3125rem;
    line-height: 2.0952380952;
  }
}
@media (min-width: 55em) {
  .c-site-header__button {
    font-size: 1.4375rem;
    line-height: 2.0869565217;
  }
}
@media (min-width: 75em) {
  .c-site-header__button {
    font-size: 1.5625rem;
    line-height: 2.08;
  }
}
@media (max-width: 34.9375em) {
  .c-site-header__button {
    width: 3rem;
    height: 3rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-header__button {
    width: 4rem;
    height: 4rem;
  }
}
.c-site-header__button .c-hamburger {
  margin: 0 auto;
}

@media (max-width: 54.9375em) {
  .c-site-header__mobile {
    margin-left: -1rem;
    transition: 0.3s box-shadow ease-in-out;
    box-shadow: 0.0625rem 0 0 0 transparent;
  }
  .has-scrolled .c-site-header__mobile {
    box-shadow: 0.0625rem 0 0 0 #436173;
  }
}
@media (min-width: 55em) {
  .c-site-header__mobile {
    display: none;
  }
}

@media (max-width: 34.9375em) {
  .c-site-header__phone .o-icon {
    font-size: 1.25rem;
    line-height: 1;
  }
}
@media (max-width: 34.9375em) and (min-width: 35em) {
  .c-site-header__phone .o-icon {
    font-size: 1.3125rem;
    line-height: 0.9523809524;
  }
}
@media (max-width: 34.9375em) and (min-width: 55em) {
  .c-site-header__phone .o-icon {
    font-size: 1.4375rem;
    line-height: 1.0434782609;
  }
}
@media (max-width: 34.9375em) and (min-width: 75em) {
  .c-site-header__phone .o-icon {
    font-size: 1.5625rem;
    line-height: 0.96;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-header__phone .o-icon {
    font-size: 1.4375rem;
    line-height: 1.0434782609;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) and (min-width: 35em) {
  .c-site-header__phone .o-icon {
    font-size: 1.5rem;
    line-height: 1;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) and (min-width: 55em) {
  .c-site-header__phone .o-icon {
    font-size: 1.75rem;
    line-height: 1;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) and (min-width: 75em) {
  .c-site-header__phone .o-icon {
    font-size: 1.9375rem;
    line-height: 1.0322580645;
  }
}
@media (max-width: 54.9375em) {
  .c-site-header__phone {
    margin-right: -1rem;
    transition: 0.3s box-shadow ease-in-out;
    box-shadow: 0.0625rem 0 0 0 transparent;
  }
  .has-scrolled .c-site-header__phone {
    box-shadow: -0.0625rem 0 0 0 #436173;
  }
  .c-site-header__phone .copy {
    display: none;
  }
}
@media (min-width: 55em) {
  .c-site-header__phone {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #ffffff;
  }
  .c-site-header__phone:hover {
    color: #ffffff;
  }
  .c-site-header__phone .o-icon {
    display: none;
  }
  .c-site-header__phone .copy {
    font-size: 1rem;
    line-height: 1;
    line-height: 3rem;
    padding-left: 0.75rem;
    font-weight: 700;
  }
}
@media (min-width: 75em) {
  .c-site-header__phone .copy {
    font-size: 1.25rem;
    line-height: 1;
  }
}
@media (min-width: 75em) and (min-width: 35em) {
  .c-site-header__phone .copy {
    font-size: 1.3125rem;
    line-height: 0.9523809524;
  }
}
@media (min-width: 75em) and (min-width: 55em) {
  .c-site-header__phone .copy {
    font-size: 1.4375rem;
    line-height: 1.0434782609;
  }
}
@media (min-width: 75em) and (min-width: 75em) {
  .c-site-header__phone .copy {
    font-size: 1.5625rem;
    line-height: 0.96;
  }
}

.c-site-header__left {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  font-size: 0;
}
.c-site-header__left a,
.c-site-header__left a:hover {
  color: inherit;
  text-decoration: none;
}
@media (max-width: 34.9375em) {
  .c-site-header__left {
    max-width: 12rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-header__left {
    max-width: 15rem;
  }
}
@media (max-width: 54.9375em) {
  .c-site-header__left {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
}
@media (min-width: 55em) {
  .c-site-header__left {
    margin-right: 0.75rem;
    max-width: 28%;
  }
}
@media (min-width: 75em) {
  .c-site-header__left {
    margin-right: 2rem;
    max-width: 19rem;
  }
}

.c-site-header__right {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 54.9375em) {
  .c-site-header__nav {
    display: none;
  }
}

.c-hero {
  padding-bottom: 6rem;
  margin-bottom: 5rem;
  background-color: #2e4f63;
  border-bottom: 0.5rem solid #3cba8c;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 34.9375em) {
  .c-hero {
    padding-top: 9rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-hero {
    padding-top: 10rem;
  }
}
@media (min-width: 55em) {
  .c-hero {
    padding-top: 11rem;
  }
}
.c-hero .c-hero__content {
  position: relative;
  z-index: 1;
}
.c-hero .c-hero__main {
  background-color: #2e4f63;
  padding: 3rem 2rem;
}
.c-hero .c-hero__main .c-hummingbird {
  border-left: 0.35rem solid #3cba8c;
}
.c-hero .c-hero__title {
  color: #ffffff;
}
.c-hero .c-hero__description {
  font-size: 1rem;
  line-height: 1.75;
  max-width: 40rem;
  color: #ffffff;
}
.c-hero .c-hero__disclaimer {
  font-size: 0.75rem;
  line-height: 2;
}
.c-hero .c-hummingbird {
  max-width: 40rem;
  color: #ffffff;
}
.c-hero:after {
  content: "";
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 79, 99, 0.8);
}
.c-hero .c-cta--button {
  text-align: left;
  margin-top: 2rem;
}
.c-hero .c-cta--button .c-button {
  font-size: 0.9375rem;
}

.c-hero--featured {
  text-align: center;
  margin-bottom: 0;
}
@media (max-width: 34.9375em) {
  .c-hero--featured {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-hero--featured {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}
@media (min-width: 55em) {
  .c-hero--featured {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}
.c-hero--featured .c-hero__content {
  max-width: 100%;
}
.c-hero--featured .c-hero__title {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}
.c-hero--featured .c-hero__description {
  margin: 2.5rem auto;
}
.c-hero--featured .c-button {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;
}
.u-hover-children:hover .c-hero--featured .c-button, .c-hero--featured .c-button:hover {
  background-color: #d22323;
  border-color: #d22323;
  color: #ffffff;
}

.c-hero--brand {
  margin-bottom: 0;
}
@media (max-width: 34.9375em) {
  .c-hero--brand {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-hero--brand {
    padding-top: 7rem;
    padding-bottom: 4rem;
  }
}
@media (min-width: 55em) {
  .c-hero--brand {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
}
.c-hero--brand .c-hero__content {
  max-width: 48rem;
}
.c-hero--brand .c-hero__description {
  border-left: 0.3125rem solid #3cba8c;
  padding-left: 1.25rem;
  max-width: none;
}

.c-hero__before {
  padding-left: 0;
  padding-right: 0;
}

.c-hero--two-column .c-cta {
  margin: 0;
  padding: 0;
  max-width: none;
  align-items: flex-start;
}

.c-site-footer {
  color: #cccccc;
  background-color: #333333;
  padding-top: 4rem;
  padding-bottom: 6rem;
  margin-top: auto;
  text-align: center;
}

.c-site-footer__top {
  padding-bottom: 2.5rem;
  border-bottom: 0.25rem solid #3cba8c;
  margin-bottom: 2.5rem;
}
@media (max-width: 54.9375em) {
  .c-site-footer__top .c-button {
    display: none;
  }
}
@media (min-width: 55em) {
  .c-site-footer__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.c-site-footer__logo {
  max-width: 20rem;
  width: 100%;
}
@media (max-width: 54.9375em) {
  .c-site-footer__logo {
    margin: 0 auto;
  }
}
.c-site-footer__logo .c-logo {
  background-image: url("../assets/img/logo--primary.svg");
}

.c-site-footer__menu {
  margin-bottom: 4rem;
}
@media (max-width: 54.9375em) {
  .c-site-footer__menu {
    font-size: 1rem;
    line-height: 2;
  }
}
@media (min-width: 55em) {
  .c-site-footer__menu {
    font-size: 0.875rem;
    line-height: 2;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-footer__menu .c-menu__list {
    columns: 2;
  }
}
@media (min-width: 55em) {
  .c-site-footer__menu .c-menu__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}
.c-site-footer__menu .c-menu__item {
  letter-spacing: 0.0625rem;
}
@media (max-width: 54.9375em) {
  .c-site-footer__menu .c-menu__item {
    margin-bottom: 1rem;
  }
}
@media (min-width: 55em) {
  .c-site-footer__menu .c-menu__item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.c-site-footer__menu .c-menu__link {
  color: inherit;
  text-decoration: none;
}
.c-site-footer__menu .c-menu__link:hover {
  color: #ffffff;
  text-decoration: underline;
}

.c-site-footer__disclaimer {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  margin-left: auto;
  margin-right: auto;
  font-style: normal;
  color: #b3b3b3;
}
.c-site-footer__disclaimer a {
  color: inherit;
  text-decoration: none;
}
.c-site-footer__disclaimer a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.c-site-footer__disclaimer .c-disclaimer {
  font-size: 0.75rem;
}

@media (min-width: 55em) {
  .c-site-footer__disclaimer-part {
    display: inline-block;
  }
}

.c-site-footer__disclaimer-part--copyright {
  width: 100%;
}
.c-site-footer__disclaimer-part--copyright span {
  display: inline-block;
}
@media (max-width: 54.9375em) {
  .c-site-footer__disclaimer-part--copyright span:first-child {
    display: block;
  }
  .c-site-footer__disclaimer-part--copyright span:last-child:before {
    content: " | ";
  }
}
@media (min-width: 55em) {
  .c-site-footer__disclaimer-part--copyright span:before {
    content: " | ";
  }
  .c-site-footer__disclaimer-part--copyright span:first-child:before {
    content: "";
  }
}

.c-site-footer__disclaimer-part--address {
  margin-top: 0.75rem;
  width: 100%;
}
.c-site-footer__disclaimer-part--address span {
  display: inline-block;
}

.c-media-block {
  margin-left: 0;
  margin-right: 0;
}

.c-media-block--card {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  align-items: stretch;
  text-align: center;
}
@media (max-width: 54.9375em) {
  .c-media-block--card {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 34.9375em) {
  .c-media-block--card .c-media-block__content {
    padding: 0 1.5rem 2rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-media-block--card .c-media-block__content {
    padding: 0 2.5rem 2.5rem;
  }
}
@media (min-width: 55em) {
  .c-media-block--card .c-media-block__content {
    padding: 2.5rem;
  }
}
.c-media-block--card .c-media-block__media {
  padding: 0;
}
.c-media-block--card .c-media-block__image {
  width: 100%;
  height: 100%;
}
.c-media-block--card .o-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.c-expand-menu__toggle {
  color: #3192b2;
}

.c-expand-menu__item--d1 {
  margin-bottom: 0;
}

.c-expand-menu__list--d2 {
  border: 0.125rem solid #eeeeee;
  border-top: 0;
}

.c-expand-menu__link--d2 {
  padding: 0.75rem 1rem;
}

.c-related-posts {
  background-color: #eeeeee;
}
.c-related-posts .c-related-posts__title {
  background-color: #2e4f63;
  padding: 1rem 1.5rem;
  color: #ffffff;
  margin-bottom: 0;
}
.c-related-posts .c-post-block {
  margin: 0.5rem 0;
}
.c-related-posts .c-post-block__link {
  text-decoration: none;
}
.c-related-posts .c-post-block__title {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Aleo, Georgia, serif;
  font-weight: 700;
  color: #333333;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  margin: 0;
  color: #2e4f63;
}
.c-related-posts .c-post-block__after {
  font-size: 0.9rem;
}

.c-related-posts__list {
  flex-direction: column;
  padding: 0.5rem 1.5rem;
}

.c-site-menu__list {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-grow: 1;
}
.c-site-menu__item--has-children:hover > .c-site-menu__list {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s;
}

.c-site-menu__list--d2 {
  position: absolute;
  top: 100%;
  right: 5%;
  background-color: #ffffff;
  flex-direction: column;
  min-width: 15rem;
  max-width: 48rem;
  padding: 2.5rem;
  visibility: hidden;
  opacity: 0;
  transition-property: opacity, visibility;
  transition-duration: 0.2s, 0s;
  transition-delay: 0s, 0.2s;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  width: 100%;
}
@media (min-width: 75em) {
  .c-site-menu__list--d2 {
    right: 11%;
  }
}
.c-site-menu__item--has-children:hover .c-site-menu__list--d2 {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s;
}
.c-site-menu__list--d2 .c-cta {
  margin: 0;
  color: #333333;
  line-height: normal;
}
.c-site-menu__item--d1:last-child .c-site-menu__list--d2 {
  width: auto;
}

.c-site-menu__item--d1 {
  font-size: 0.875rem;
  line-height: 5rem;
  height: 5rem;
  padding: 0 1rem;
  color: #ffffff;
  position: relative;
  background-color: transparent;
  transition: background-color 0.2s ease;
  position: inherit;
}
.c-site-menu__item--d1:hover {
  background-color: transparent;
  color: #ffffff;
  border-bottom: 0.25rem solid #3cba8c;
}
@media (min-width: 55em) and (max-width: 74.9375em) {
  .c-site-menu__item--d1 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.c-site-menu__item--d2 {
  width: 100%;
  left: auto;
  right: 0;
  margin: 0.75rem 0;
  color: #333333;
  padding: 0.5rem 0;
}
.c-site-menu__item--d2:first-child {
  margin-top: 0;
}
.c-site-menu__item--d2:last-child {
  margin-bottom: 0;
}

.c-site-menu__link {
  color: inherit;
  text-decoration: none;
}
.c-site-menu__link:hover {
  text-decoration: none;
  color: #ffffff;
}

.c-site-menu__link--d1 {
  display: inline-block;
  line-height: inherit;
  color: inherit;
}
.c-site-menu__item--has-children:hover .c-site-menu__link--d1 {
  color: inherit;
}
@media (min-width: 55em) {
  .c-site-menu__item--has-children .c-site-menu__link--d1:after {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: 0.25rem;
    top: 0.0625rem;
    position: relative;
    content: "\e908";
  }
}

.c-site-menu__link--d2 {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: block;
  color: inherit;
}
.c-site-menu__link--d2:hover {
  color: inherit;
  text-decoration: underline;
}
.c-site-menu__item--has-children:hover .c-site-menu__link--d2 {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s;
}

.c-site-menu__dropdown-col {
  flex: 1;
  padding: 0 1rem;
}

.c-section .c-media-block {
  margin-bottom: 1.5rem;
  margin-top: unset;
}
.c-section .c-media-block:last-child {
  margin-bottom: 0;
}
.c-section .c-media-block h3 {
  padding-left: 0;
  border-left: 0;
  color: #333333;
}
.c-section .c-media-block h2 {
  margin-top: unset;
}
.c-section h2.c-section__title {
  font-size: 2.4rem;
  margin-bottom: 2rem;
}

.c-section__title {
  font-weight: normal;
}

.c-section--overlap {
  padding-top: 0;
  padding-bottom: 0;
  overflow: visible;
}
.c-section--overlap .c-section__content {
  position: relative;
  z-index: 1;
}
@media (max-width: 54.9375em) {
  .c-section--overlap .c-section__content {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
}
@media (min-width: 55em) {
  .c-section--overlap .c-section__content {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
}
.c-section--overlap .c-media-block {
  margin-top: 0;
}

.c-section--primary {
  background-color: #3cba8c;
}
.c-section--primary .c-section__title,
.c-section--primary .c-section__description {
  color: #ffffff;
}

.c-section--dark {
  background: #333333;
}
.c-section--dark .c-section__title,
.c-section--dark .c-section__description {
  color: #ffffff;
}

.c-section--landing-content {
  background: #ffffff;
}
@media (min-width: 55em) {
  .c-section--landing-content {
    padding-top: 7rem;
    padding-bottom: 5rem;
  }
}
.c-section--landing-content .c-section__content {
  max-width: 45rem;
  margin: 0 auto;
}
.c-section--landing-content .c-wysiwyg {
  max-width: none;
}

.c-section--padding-sm {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media (min-width: 55em) {
  .c-section--padding-sm {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
.c-section--padding-sm .c-section__title {
  margin-bottom: 1rem;
}
.c-section--padding-sm .c-section__content > :first-child,
.c-section--padding-sm .c-section__content .c-section__title {
  margin-top: 0;
}
.c-section--padding-sm .c-wysiwyg:last-child {
  margin-bottom: 0;
}

.c-section--center .c-section__title {
  margin-left: auto;
  margin-right: auto;
}

.c-section--text-align-center {
  text-align: center;
}

.c-section--white {
  background: #ffffff;
  padding: 4rem;
}
.c-section--white .c-section__content .c-section {
  padding: unset;
}

.c-section__header > :first-child {
  margin-top: 0;
}
.c-section__header > :last-child {
  margin-bottom: 0;
}

.c-widget__title {
  font-family: "Aleo", "Georgia", serif;
  display: block;
  background-color: #2e4f63;
  color: #ffffff;
  padding: 1rem 1.25rem;
  margin-bottom: 0;
}

.c-widget--menu {
  margin-bottom: 3rem;
}

@media (min-width: 55em) {
  .c-widget--sticky {
    top: 7rem;
    transition: 0.3s top ease-in-out;
  }
  .hide-site-header .c-widget--sticky {
    top: 2rem;
  }
}

.c-modal {
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  max-width: 56rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.c-modal--ccpa {
  max-width: none;
}

.c-modal__header {
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #3cba8c;
  text-align: center;
}

.c-modal__divider {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  border-bottom: 0.062rem solid #eeeeee;
}

.c-modal__divider-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #3cba8c;
  background-color: #ffffff;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.sales-rep {
  padding: 0;
  border-radius: unset;
}
@media (min-width: 35em) {
  .sales-rep .c-modal__content {
    display: flex;
  }
}
.sales-rep .c-modal__content .form-modal-intro {
  background-color: #3192b2;
  padding: 2.5rem;
  color: #ffffff;
  text-align: left;
  position: relative;
}
.sales-rep .c-modal__content .form-modal-intro .form-modal__title {
  font-family: "Aleo", "Georgia", serif;
  color: #ffffff;
  font-size: 2rem;
  line-height: 1.25;
  text-align: left;
}
.sales-rep .c-modal__content .form-modal-intro .form-modal__info {
  background-color: unset;
  padding: 0;
  margin-top: 2rem;
  line-height: 1.62;
}
.sales-rep .c-modal__content .form-modal-intro .form-modal__img {
  width: 40%;
  padding-top: 2rem;
  position: absolute;
  bottom: 0;
}
@media (max-width: 54.9375em) {
  .sales-rep .c-modal__content .form-modal-intro .form-modal__img {
    display: none;
  }
}
.sales-rep .c-modal__content .c-form {
  padding: 2.5rem;
}
.sales-rep .c-modal__content .c-form .c-button {
  background-color: #2e4f63;
  border-color: #2e4f63;
  color: #000000;
}

.c-page--single .c-hero__content,
.c-page--single .c-page__footer {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}
.c-page--single .c-page__content {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}
.c-page--single .c-page__main {
  max-width: 60rem;
  margin: 3rem auto;
}
.c-page--single .c-page__main .c-wysiwyg {
  margin-left: auto;
  margin-right: auto;
}
.c-page--single .c-page__featured {
  margin-top: 0;
}
@media (min-width: 35em) {
  .c-page--single .c-page__featured {
    max-width: 50%;
    padding-left: 2rem;
  }
}

.c-404 {
  background-color: rgb(230, 242, 246);
}

.c-about {
  background-color: rgb(230, 242, 246);
}
.c-about .c-section__title::after {
  display: none;
}

.page-template-contact .c-action-block h3 {
  margin-bottom: 0;
  margin-top: 2.5rem;
}
.page-template-contact .c-action-block p {
  margin-top: 0.2rem;
}

.c-home {
  background-color: rgb(230, 242, 246);
}

@media (min-width: 55em) {
  .c-home__selling-payments .c-media-block__media {
    width: 60%;
  }
  .c-home__selling-payments .c-media-block__content {
    width: 40%;
  }
}

@media (min-width: 55em) {
  .c-home__cashing-out .c-media-block__media {
    width: 40%;
  }
  .c-home__cashing-out .c-media-block__content {
    width: 60%;
  }
}

.hero-h2 {
  color: #ffffff;
  margin-bottom: -1.5rem;
}

.c-landing {
  background-color: rgb(230, 242, 246);
}
@media (min-width: 55em) {
  .c-landing .c-mobile-copy,
  .c-landing .c-mobile-button {
    display: none;
  }
}
.c-landing .c-mobile-copy {
  font-weight: 700;
}
.c-landing .c-hero__description {
  margin-bottom: 0;
}

.c-form--landing {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  max-width: 45rem;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 34.9375em) {
  .c-form--landing {
    padding: 1.5rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-form--landing {
    padding: 2rem;
  }
}
@media (min-width: 55em) {
  .c-form--landing {
    padding: 3rem;
  }
}

.c-form__group--half {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 35em) {
  .c-form__group--half {
    width: 50%;
  }
}

.c-form__group--no-label .c-form__label {
  display: none;
}

.c-form__group--tcpa {
  padding: 1rem;
  background-color: #eeeeee;
}
.c-form__group--tcpa .c-form__label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #666666;
}
.c-form__group--tcpa .c-form__help-text {
  font-size: 0.75rem;
  line-height: 1.6666666667;
  display: block;
  color: #666666;
}

.c-form__submit {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.c-form__buttons--center {
  text-align: center;
}

.c-page__main {
  margin-bottom: 0;
}

.c-page--backpage {
  margin-bottom: 4rem;
}
.c-page--backpage .c-page__content {
  flex-direction: row;
}
.c-page--backpage .c-page__main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.c-page--backpage .c-attribution {
  width: 100%;
  max-width: 40rem;
}

@media (min-width: 55em) {
  .c-page__aside > .c-widget {
    max-width: 18rem;
    margin-right: 0;
  }
}

.c-profile-header__image {
  border-radius: 50%;
  margin-bottom: 1rem;
}

.c-hero--profile {
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.c-hero--profile .c-hero__subtitle {
  color: #ffffff;
  margin: 0;
}
.c-hero--profile .c-profile-contact__link {
  color: #ffffff;
}

.c-page--profile .c-wysiwyg {
  margin-top: 0;
}
.c-page--profile .c-page__aside {
  margin-bottom: 0;
}
.c-page--profile .c-page__aside .c-related-posts {
  margin-bottom: 2rem;
}
.c-page--profile .c-page__footer {
  border-top: 0.25rem solid #3cba8c;
  background-color: #eeeeee;
  padding-bottom: 0;
  padding-top: 0.0625rem;
}

@media (min-width: 35em) {
  .c-hero--profile {
    padding-top: 7rem;
    padding-bottom: 0;
    margin-bottom: 5rem;
    text-align: left;
  }
  .c-hero--profile .c-hero__content {
    display: flex;
    align-items: center;
    max-width: 45rem;
  }
  .c-hero--profile .c-hero__before {
    width: auto;
  }
  .c-hero--profile .c-hero__main {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .c-hero--profile .c-hero__after {
    clear: both;
  }
  .c-profile-header__image {
    float: left;
    margin-bottom: -3rem;
  }
  .c-page--profile .c-page__content {
    padding-bottom: 2rem;
  }
}
.c-thank-you {
  background-color: rgb(230, 242, 246);
  padding-bottom: 4rem;
}

.c-calculator .c-hero--featured {
  padding-bottom: 3rem;
}
@media (min-width: 55em) {
  .c-calculator .c-hero--featured {
    padding-bottom: 6rem;
  }
}
.c-calculator .calculation {
  background-color: rgb(230, 242, 246);
  border-radius: 0.5rem;
  padding: 1.5rem;
  text-align: center;
}
.c-calculator .calculation__result {
  font-size: 1.625rem;
  line-height: 1.2307692308;
  font-family: Aleo, Georgia, serif;
  font-weight: 400;
  color: #333333;
  margin: 0;
  line-height: 1;
  display: block;
  font-weight: bold;
}
@media (min-width: 35em) {
  .c-calculator .calculation__result {
    font-size: 1.75rem;
    line-height: 1.1428571429;
  }
}
@media (min-width: 55em) {
  .c-calculator .calculation__result {
    font-size: 2.0625rem;
    line-height: 1.2121212121;
  }
}
@media (min-width: 75em) {
  .c-calculator .calculation__result {
    font-size: 2.4375rem;
    line-height: 1.2307692308;
  }
}
.c-calculator .calculator__title {
  font-size: 1.25rem;
  line-height: 2;
  margin: 1.25rem 0;
  text-align: center;
}
@media (min-width: 35em) {
  .c-calculator .calculator__title {
    font-size: 1.3125rem;
    line-height: 2.0952380952;
  }
}
@media (min-width: 55em) {
  .c-calculator .calculator__title {
    font-size: 1.4375rem;
    line-height: 2.0869565217;
  }
}
@media (min-width: 75em) {
  .c-calculator .calculator__title {
    font-size: 1.5625rem;
    line-height: 2.08;
  }
}

@font-face {
  font-family: "icomoon";
  src: url("../../structuredsettlements/assets/fonts/icomoon/icomoon.eot?pq23sr");
  src: url("../../structuredsettlements/assets/fonts/icomoon/icomoon.eot?pq23sr#iefix") format("embedded-opentype"), url("../../structuredsettlements/assets/fonts/icomoon/icomoon.woff2?pq23sr") format("woff2"), url("../../structuredsettlements/assets/fonts/icomoon/icomoon.ttf?pq23sr") format("truetype"), url("../../structuredsettlements/assets/fonts/icomoon/icomoon.woff?pq23sr") format("woff"), url("../../structuredsettlements/assets/fonts/icomoon/icomoon.svg?pq23sr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.o-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.o-icon--radio_button_checked:after {
  content: "\e904";
}

.o-icon--radio_button_unchecked:after {
  content: "\e905";
}

.o-icon--home:after {
  content: "\e900";
}

.o-icon--chevron-right:after {
  content: "\e906";
}

.o-icon--chevron-left:after {
  content: "\e907";
}

.o-icon--chevron-down:after {
  content: "\e908";
}

.o-icon--chevron-up:after {
  content: "\e909";
}

.o-icon--close:after {
  content: "\e901";
}

.o-icon--search:after {
  content: "\e903";
}

.o-icon--menu:after {
  content: "\e902";
}

.o-icon--plus:after {
  content: "\f067";
}

.o-icon--minus:after {
  content: "\f068";
}

.o-icon--check:after {
  content: "\f00c";
}

.o-icon--x:after {
  content: "\f00d";
}

.o-icon--lock:after {
  content: "\f023";
}

.o-icon--location:after {
  content: "\f041";
}

.o-icon--check-square-o:after {
  content: "\f046";
}

.o-icon--play:after {
  content: "\f04b";
}

.o-icon--pause:after {
  content: "\f04c";
}

.o-icon--info:after {
  content: "\f05a";
}

.o-icon--arrow-left:after {
  content: "\f060";
}

.o-icon--arrow-right:after {
  content: "\f061";
}

.o-icon--arrow-up:after {
  content: "\f062";
}

.o-icon--arrow-down:after {
  content: "\f063";
}

.o-icon--warning:after {
  content: "\f071";
}

.o-icon--external:after {
  content: "\f08e";
}

.o-icon--phone:after {
  content: "\f095";
}

.o-icon--square-o:after {
  content: "\f096";
}

.o-icon--twitter:after {
  content: "\f099";
}

.o-icon--facebook:after {
  content: "\f09a";
}

.o-icon--expand:after {
  content: "\f0b2";
}

.o-icon--chain:after {
  content: "\f0c1";
}

.o-icon--link:after {
  content: "\f0c1";
}

.o-icon--google-plus:after {
  content: "\f0d5";
}

.o-icon--caret-down:after {
  content: "\f0d7";
}

.o-icon--caret-up:after {
  content: "\f0d8";
}

.o-icon--envelope:after {
  content: "\f0e0";
}

.o-icon--linkedin:after {
  content: "\f0e1";
}

.o-icon--angle-left:after {
  content: "\f104";
}

.o-icon--angle-right:after {
  content: "\f105";
}

.o-icon--angle-up:after {
  content: "\f106";
}

.o-icon--angle-down:after {
  content: "\f107";
}

.o-icon--quote-left:after {
  content: "\f10d";
}

.o-icon--quote-right:after {
  content: "\f10e";
}

.o-icon--youtube:after {
  content: "\f16a";
}

.o-icon--instagram:after {
  content: "\f16d";
}

.o-icon--question:after {
  content: "\f29c";
}

.o-icon--quora:after {
  content: "\f2c4";
}

.o-icon--bills:after {
  content: "\e90a";
}

.o-icon--chatbubble:after {
  content: "\e90b";
}

.o-icon--moneybag:after {
  content: "\e90c";
}

.o-icon--phone-line:after {
  content: "\e90d";
}

.o-icon--scales:after {
  content: "\e90e";
}

.u-clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.u-cover-link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

@media (max-width: 54.9375em) {
  body.no-scroll {
    overflow: hidden;
  }
}

.u-sr-only {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  color: #000000;
  background-color: #ffffff;
}

.u-sr-only-focusable:active, .u-sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
  max-width: 60rem;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #ccc;
}
.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
  * Remove all paddings around the image on small screen
  */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
#c-livechat {
  position: fixed;
  bottom: 0.5rem;
  right: 1rem;
  cursor: pointer;
  overflow: hidden;
  visibility: visible;
  z-index: 9999999;
  border: 0;
  padding: 0;
  transition: transform 0.2s ease-in-out;
  transform: translateY(0%);
}
#c-livechat:focus, #c-livechat:active {
  outline: none;
  border: none;
}
@media (max-width: 54.9375em) {
  #c-livechat {
    display: none;
  }
}

#c-livechat__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
#c-livechat__loading.u-show {
  display: block;
}

#livechat-compact-container {
  display: none;
}