.c-landing {
    background-color: color-step($accent, 50);

    .c-mobile-copy,
    .c-mobile-button {
        @include media-breakpoint-up(l) {
            display: none;
        }
    }

    .c-mobile-copy {
        font-weight: $font-weight--bold;
    }

    .c-hero__description {
        margin-bottom: 0;
    }
}

.c-form--landing {
    @include make-card;
    background-color: $white;
    max-width: 45rem;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-only(s) {
        padding: $spacer--l;
    }

    @include media-breakpoint-only(m) {
        padding: $spacer--xl;
    }
    @include media-breakpoint-up(l) {
        padding: $spacer--3xl;
    }
}

.c-form__group--half {
    @include make-col-ready;

    @include media-breakpoint-up(m) {
        width: 50%;
    }
}

.c-form__group--no-label {
    .c-form__label {
        display: none;
    }
}

.c-form__group--tcpa {
    padding: $spacer;
    background-color: $gray-100;

    .c-form__label {
        @include type-scale('s', 1.5);
        color: $gray-600;
    }

    .c-form__help-text {
        @include type-scale('xs', 1.5);
        display: block;
        color: $gray-600;
    }
}

.c-form__submit {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.c-form__buttons--center {
    text-align: center;
}