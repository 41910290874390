.c-modal {
    background-color: $white;
    padding: $spacer--xl $spacer--l;

    @include media-breakpoint-only(m) {
        padding: $spacer--xl;
    }

    @include media-breakpoint-up(l) {
        padding: $spacer--2xl;
    }
}

.c-modal__title {
    @include make-h2();
}