.c-section {

    .c-media-block {
        margin-bottom: $spacer--l;
        margin-top: unset;

        &:last-child {
            margin-bottom: 0;
        }

        h3 {
            padding-left: 0;
            border-left: 0;
            color: $dark;
        }
        h2 {
            margin-top: unset;
        }
    }
    h2.c-section__title {
        font-size: 2.4rem;
        margin-bottom: 2rem;
    }
}

.c-section__title {
    font-weight: normal;
}

.c-section--overlap {
    padding-top: 0;
    padding-bottom: 0;
    overflow: visible;

    .c-section__content {
        position: relative;
        z-index: 1;

        @include media-breakpoint-down(m) {
            margin-top: -$spacer--2xl;
            margin-bottom: -$spacer--2xl;
        }

        @include media-breakpoint-up(l) {
            margin-top: -$spacer--4xl;
            margin-bottom: -$spacer--4xl;
        }
    }

    .c-media-block {
        margin-top: 0;
    }
}

.c-section--primary {
    background-color: $primary;

    .c-section__title,
    .c-section__description {
        color: $white;
    }
}

.c-section--dark {
    background: $dark;

    .c-section__title,
    .c-section__description {
        color: $white;
    }
}

.c-section--landing-content {
    background: $white;

    @include media-breakpoint-up(l) {
        padding-top: 7rem;
        padding-bottom: 5rem;
    }
    
    .c-section__content {
        max-width: 45rem;
        margin: 0 auto;
    }

    .c-wysiwyg {
        max-width: none;
    }
}

//rename eventually if we lose the overlap style 
.c-section--padding-sm {
    padding-top: $spacer--2xl;
    padding-bottom: $spacer--2xl;

    @include media-breakpoint-up(l) {
        padding-top: $spacer--4xl;
        padding-bottom: $spacer--4xl;
    }

    .c-section__title {
        margin-bottom: $spacer;
    }

    .c-section__content > :first-child,
    .c-section__content .c-section__title {
        margin-top: 0;
    }

    .c-wysiwyg:last-child {
        margin-bottom: 0;
    }
}

.c-section--center {
    .c-section__title {
        margin-left: auto;
        margin-right: auto;
    }
}

.c-section--text-align-center {
    text-align: center;
}

.c-section--white {
    background: $white;
    padding: 4rem;
    .c-section__content {
        .c-section {
            padding: unset;
        }
    }
}

.c-section__header {
    &>:first-child {
        margin-top: 0;
    }
    &>:last-child {
        margin-bottom: 0;
    }
}
