@mixin make-button() {
    @include get-styles-from-map($button-styles);
    display: inline-block;
    cursor: pointer;

    .u-hover-children:hover &,
    &:hover {
        text-decoration: none;
        border-color: color-level(map-get($button-styles, background-color), 3);
        background-color: color-level(map-get($button-styles, background-color), 3);
        color: color-yiq(color-level(map-get($button-styles, background-color), 3));
    }

    &:focus {
        outline: 0;
    }
}

@mixin make-button-variant(
    $background-color,
    $border-color: $background-color,
    $color: color-yiq($background-color),
    $hover-background-color: color-level($background-color, 3),
    $hover-border-color: color-level($border-color, 3),
    $hover-color: color-yiq(color-level($background-color, 3))
) {
    background-color: $background-color;
    border-color: $border-color;
    color: $color;

    .u-hover-children:hover &,
    &:hover {
        background-color: $hover-background-color;
        border-color: $hover-border-color;
        color: $hover-color;
    }
}

@mixin make-button-xs() {
    @include get-styles-from-map($button-styles--xs);
}

@mixin make-button-s() {
    @include get-styles-from-map($button-styles--s);
}

@mixin make-button-l() {
    @include get-styles-from-map($button-styles--l);
}
