.c-pro-con {}

.c-pro-con__lists {
    @include make-row();
    @include media-breakpoint-up(m) {
        flex-wrap: nowrap;
    }
}

.c-pro-con__list {
    width: 100%;
    padding: $spacer--l;

    >:first-child {
        margin-top: 0;
    }
    >*:last-child {
        margin-bottom: 0;
    }
}
.c-pro-con__list--cons {
    background-color: color-step($error, 50);
}
.c-pro-con__list--pros {
    background-color: color-step($success, 50);
}

.c-pro-con__sub-title {
    @include make-h4();
    padding-left: 0;
    border-left: 0;

    .c-pro-con__list--pros & {
        color: color-step($success, 900);
    }
    .c-pro-con__list--cons & {
        color: color-step($error, 900);
    }

    .c-wysiwyg & {
        padding: 0;
        border: 0;
    }
}

.c-wysiwyg {
    .c-ul {
        padding-left: 0;
        margin-top: 0;

        li {
            @include type-scale(base, 1.5);
            padding-left: $spacer--xl;

            &:before {
                @include make-icon;
                font-size: 1rem;
                line-height: 1.5;
                top: 0;
                left: 0;
            }
        }
    }

    .c-ul--pro {
        li {
            color: color-step($success, 900);

            &:before {
                content: icon('check');
                color: $success;
            }
        }
    }

    .c-ul--con {
        li {
            color: color-step($error, 900);
            &:before {
                content: icon('x');
                color: $error;
            }
        }
    }

}
