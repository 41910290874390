.c-action-list__item {
    @include media-breakpoint-down(m) {
        max-width: 32rem;
        margin-left: auto;
        margin-right: auto;

        &:first-child {
            .c-action-block {
                margin-top: 0;
            }
        }
        &:last-child {
            .c-action-block {
                margin-bottom: 0;
            }
        }
    }
    @include media-breakpoint-up(l) {
        display: flex;
        align-items: stretch;
        flex-basis: 0;
        flex-grow: 1;

        .c-action-block {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.c-action-list--auto {
    .c-action-list__items {
        flex-wrap: wrap;
    }

    .c-action-list__item {
        flex: 0 1 auto;
    }
}

.c-action-list--profile {
    padding: $spacer--l 0;

    .c-action-list__item {
        @include media-breakpoint-up(l) {
            flex: 0 1 22rem;
        }
    }

    .c-action-block {
        @include media-breakpoint-up(l) {
            width: 18rem;
        }
    }

    .c-action-block__subtitle {
        @include type-scale(m, 1.5);
        font-family: $font-family--serif;
        font-weight: bold;
        color: $primary;
    }

    .c-action-block__title {
        font-weight: normal;
    }

    .c-action-block__image {
        img {
            border-radius: 50%;
        }
    }
}
