.c-related-posts {
    background-color: $gray-100;

    .c-related-posts__title {
        background-color: $secondary;
        padding: 1rem 1.5rem;
        color: $white;
        margin-bottom: 0;
    }

    .c-post-block {
        margin: 0.5rem 0;
    }

    .c-post-block__link {
        text-decoration: none;
    }

    .c-post-block__title {
        @include make-h4();
        margin: 0;
        color: $secondary;
    }

    .c-post-block__after {
        font-size: 0.9rem;
    }
}

.c-related-posts__list {
    flex-direction: column;
    padding: 0.5rem 1.5rem;
}
