.c-alert {
    padding: 1rem;
    border: rem(1) solid gray('200');
    background-color: gray('100');
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}

.c-alert__icon {
}

.c-alert__icon--before {
    margin-right: 1rem;
}

.c-alert--error {
    border-color: theme-color('error');
    color: theme-color('error');
    background: rgba(theme-color('error'), 0.1);
}
