.c-mobile-nav {
    position: relative;
    z-index: 900;
}

.c-mobile-nav__drawer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: $white;
    width: 90%;
    max-width: 25rem;
    height: 100%;
    color: $dark;
    padding: $spacer;
    box-shadow: 0 rem(1) rem(1) 0 rgba($dark, 0.3);
    transition: all 0.2s ease-in-out;
    transform: translateX(-100%);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-only(s) {
        padding-top: $site-header-height--mobile + $spacer--xl;
    }
    @include media-breakpoint-only(m) {
        padding-top: $site-header-height--tablet + $spacer--xl;
    }
    @include media-breakpoint-up(l) {
        display: none;
    }

    .c-mobile-nav.is-active & {
        transform: translateX(0);
    }
}

.c-mobile-nav__mask {
    position: fixed;
    cursor: pointer;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition:  opacity 0.2s ease-in-out, visibility 0s ease-in-out 0.2s;
    opacity: 0;
    visibility: hidden;

    @include media-breakpoint-up(l) {
        width: 0;
        height: 0;
    }

    .c-mobile-nav.is-active & {
        opacity: 1;
        visibility: visible;
        transition:  opacity 0.2s ease-in-out, visibility 0s ease-in-out 0s;
    }
}

.c-mobile-nav__title {
    @include type-scale(base, 1);
    margin-bottom: $spacer--l;
    font-family: $font-family--serif;
    font-weight: $font-weight--normal;
    font-style: italic;
    display: block;

    &:after {
        margin: $spacer--xs 0 0;
        content: '';
        height: $spacer--3xs;
        width: $spacer--4xl;
        background: $secondary;
        display: block;
    }
}
