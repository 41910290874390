.c-expand-menu {}





.c-expand-menu__list {}

.c-expand-menu__list--d1 {
    padding-left: 0;
}

.c-expand-menu__list--d2 {
    display: none;
    width: 100%;
    padding: $spacer--s 0;

    .c-expand-menu__item--has-children.is-active & {
        display: block;
    }
}





.c-expand-menu__item {
    @include type-scale(base, 1.75);
}

.c-expand-menu__item--d1 {
    margin-bottom: $spacer;
}

.c-expand-menu__item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.c-expand-menu__item-wrapper--d1 {
    background-color: $expand-menu-item-background-color;
    color: $expand-menu-link-color;
}






.c-expand-menu__link {
    @include type-scale(s, 1.5);
    display: inline-block;
    color: $expand-menu-link-color;
    flex-grow: 1;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        text-decoration: underline;
        color: $expand-menu-link-color-hover;
    }
    &.c-expand-menu__link--current {
        font-weight: bold;
        color: $expand-menu-link-color-active;
    }
}

.c-expand-menu__link--d1 {
    @include type-scale(s, 2);
    border-left: rem(2) solid $expand-menu-link-d1-border-hover;
    padding: $spacer;
}
.c-expand-menu__link--d2 {
    color: $expand-menu-link-d2-color;
    padding: $spacer--xs $spacer;
}







.c-expand-menu__toggle {
    @include make-button-variant(transparent, transparent, $primary, transparent, transparent, $dark);
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
    padding: 0;
    border: 0;
    display: block;
}
