.c-cta {
    border-top: 0;
    border-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

.c-cta__icon {
    font-size: 3.25rem;
    margin-bottom: $spacer--l;
}
.c-cta__copy {
    max-width: 100%;
}
.c-cta__title {
    @include type-scale(m, 1.5);
}
.c-cta__description {
    @include type-scale(s, 1.5);
    margin-bottom: 0;
}
.c-cta__button {
    margin-top: $spacer--l;
}

.c-cta--light {
    background-color: #2e4f63;
    padding: $spacer--m;
    border-top: 0.35rem solid #3cba8c;
    border-bottom: 0.35rem solid #3cba8c;

    .c-cta__description {
        color: $white;
    }

    .c-cta__icon {
        color: $white;
    }
    .c-cta__title {
        color: $white;
        font-weight: bold;
    }
    .c-button {
        @include make-button-variant($white);
        @include make-button-s();
        color: #2e4f63;
    }
}

.c-cta--footer {
    margin: 0;
    max-width: 100%;
    background-color: $secondary;
    color: $white;

    @include media-breakpoint-down(m) {
        padding-top: $spacer--3xl;
        padding-bottom: $spacer--3xl;
    }

    @include media-breakpoint-up(l) {
        padding-top: $spacer--5xl;
        padding-bottom: $spacer--5xl;
    }

    .c-cta__title {
        @include type-scale(l, 1.5);
    }

    .c-section--overlap + & {
        @include media-breakpoint-down(m) {
            padding-top: $spacer--3xl + $spacer--2xl;
        }
        @include media-breakpoint-up(l) {
            padding-top: $spacer--5xl + $spacer--4xl;
        }
    }
    .c-button {
        @include make-button--outline-white();
    }
}





.c-cta--inline {
    border-top: 0.35rem solid #3cba8c;
    border-bottom: 0.35rem solid #3cba8c;
    background-color: #2e4f63;
    padding: $spacer--2xl $spacer--l;

    .c-cta__title {
        @include type-scale(m, 1.25);
        display: block;
        color: $white;
        font-weight: $font-weight--bold;
        margin: 0;
    }
    .c-cta__description {
        @include type-scale(base, 1.5);
    }
    .c-button {
        @include make-button-variant($white);
        @include make-button-s();
        color: #2e4f63;
    }

    @include media-breakpoint-up(m) {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        .c-cta__copy {
            margin-right: $spacer--l;
        }

        .c-cta__title ,
        .c-cta__description {
            text-align: left;
        }
        .c-cta__description {
            margin-top: $spacer--2xs;
        }

        .c-cta__button {
            flex-shrink: 0;
            margin-top: 0;
        }
    }
}
