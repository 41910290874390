.c-share {
    width: 100%;
}
.c-share__link {
    width: auto;
}

.c-share__copy {
    textarea {
        margin-top: $spacer--xs;
    }
}