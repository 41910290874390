.c-profile-header__image {
    border-radius: 50%;
    margin-bottom: 1rem;
}

.c-hero--profile {
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    .c-hero__subtitle {
        color: $white;
        margin: 0;
    }

    .c-profile-contact__link {
        color: $white;

    }
}

.c-page--profile {

    .c-wysiwyg {
        margin-top: 0;
    }

    

    .c-page__aside {
        margin-bottom: 0;

        .c-related-posts {
            margin-bottom: 2rem;
        }
    }

    .c-page__footer {
        border-top: 0.25rem solid $primary;
        background-color: $gray-100;
        padding-bottom: 0;
        padding-top: rem(1);
    }
}

@include media-breakpoint-up(m) {
    
    .c-hero--profile {
        padding-top: 7rem;
        padding-bottom: 0;
        margin-bottom: 5rem;
        text-align: left;

        .c-hero__content {
            display: flex;
            align-items: center;
            max-width: 45rem;
        }

        .c-hero__before {
            width: auto;
        }

        .c-hero__main {
            padding-left: 2rem;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .c-hero__title {
            

        }
        

        .c-hero__after {
            clear: both;
        }
    }

    .c-profile-header__image {
        float: left;
        margin-bottom: -3rem;
    }

    .c-page--profile {
        .c-page__content {
            padding-bottom: 2rem;
        }
    }

}
