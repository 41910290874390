.c-action-block {
    padding: 0 2rem;
    width: 100%;
    
    @include media-breakpoint-up(m) {
        padding: 0;
    }
}
.c-action-block--card {
    padding: $spacer--xl;
}

.c-action-block--l {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 46rem;

    @include media-breakpoint-only(s) {
        padding: $spacer--xl $spacer--l;
    }
    @include media-breakpoint-only(m) {
        padding: $spacer--xl $spacer--2xl;
    }
    @include media-breakpoint-up(l) {
        padding: $spacer--3xl;
    }

    .c-action-block__title {
        @include make-h2;
        margin-top: 0;
        text-align: center;
        font-weight: $font-weight--normal;
    }
    .c-action-block__copy {
        text-align: left;
    }
}

.c-action-block__content {
    max-width: 100%;
}

.c-action-block__title {
    margin-top: 0;
    margin-bottom: $spacer--xs;
}

.c-action-block__image {
    margin-bottom: $spacer--l;
}

.c-action-block--dark {
    background: $dark;
    padding: $spacer--xl;

    .c-action-block__title {
        color: $white;
    }
    .c-action-block__copy {
        color: $gray-200;
    }
}

.c-action-block__icon {
    font-size: 4.5rem;
    line-height: 1;
    color: $accent;
    margin-bottom: $spacer--l;
}

.c-action-block__copy {
    margin-bottom: auto;
    
    >:first-child {
        margin-top: 0;
    }
    >:last-child {
        margin-bottom: 0;
    }
}

.c-action-block--content {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 40rem;
    padding: 0;

    .c-action-block__copy {
        text-align: left;
    }
}