.c-profile-block--card {
    @include make-card();
    background-color: $white;
    padding: $spacer--xl;

    .c-profile-block__image {
        border: $spacer--xs solid $gray-200;
        width: 10rem;
        height: 10rem;
    }

    .c-profile-block__name {
        @include make-h2();
        margin-top: 0;
        margin-bottom: 0;
    }
    .c-profile-block__title {
        @include type-scale(base, 1.5);
        color: $accent;
    }
    .c-profile-block__after {
        @include type-scale(base, 2);
        text-align: left;
        margin-top: $spacer--l;

        >:first-child {
            margin-top: 0;
        }
        >:last-child {
            margin-bottom: 0;
        }
    }
}
