.c-form {//rule needed for ajax form loading state
    position: relative;
}

.c-form__group {
    display: block;
    width: 100%;
    margin-bottom: 1.5rem;
}





//Honeypot
.c-form__group--archer-s1e5 {
    display: none;
}




//Cause & Effect selector
.c-form__group.is-hidden {
    display: none;
}




//Form Steps
.c-form__step {
    display: none;
}
.c-form__step:first-child {
    display: block;
}

.c-form__submit-error,
.c-form__network-error { 
    @include type-scale(s);
    color: $error;
    background-color: #ffffff;
    position: absolute;    
    z-index: 200;
}

.c-form__network-error {
    margin: $spacer;
}



.c-form__lightbox{
    content: '';
    position: absolute;
    z-index: 199;
    background-color: rgba($white, 0.9);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
}

.c-form__submit-animation {
    display: none;
}

.c-form--submitting {
    .c-form__submit-animation {
        display: block;
        position: absolute;
        width: rem(80);
        height: rem(80);
        z-index: 200;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &:after {
            content: " ";
            display: block;
            width: rem(64);
            height: rem(64);
            margin: rem(8);
            border-radius: 50%;
            border: rem(6) solid $secondary;
            border-color: $secondary transparent $secondary transparent;
            animation: submit-animation 1.2s linear infinite;
        }
        @keyframes submit-animation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
    }

    .c-form__lightbox {
        display: block;
    } 
}

.c-form--disabled {
    .c-form__submit-error {
        visibility: hidden;
    }
    .c-form__lightbox {
        display: block;
    } 
} 