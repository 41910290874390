$light-blue: #eaf2fd;

.c-attribution--footer {
    padding-top: 3rem;
    text-align: center;

    @include media-breakpoint-up(m) {
        text-align: left;
    }

    .c-profile-block__header {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        @include media-breakpoint-down(s) {
            flex-wrap: wrap;
            gap: 1rem;
        }
    }

    .c-attribution__author,
    .c-attribution__editor,
    .c-attribution__reviewer {
        display: inline-block;
        flex-wrap: wrap;
        margin: 0 auto 2rem;
    
        @include media-breakpoint-up(m) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .c-profile-block {
            align-items: center;
            display: block;
            flex-wrap: wrap;
            width: 100%;
    
            @include media-breakpoint-up(m) {
                display: inline-flex;
            }
        }
        .c-profile-block__image-wrapper {
            @include media-breakpoint-down(s) {
                flex-basis: 100%;
            }
        }
        .c-profile-block__image {
            margin: 0;

            @include media-breakpoint-up(m) {
                margin-right: 2rem;
            }
        }
        .c-profile-block__content {
            margin: 1rem 0;
            width: 100%;

            .c-profile-block__before {
                margin-bottom: 0;
            }
            @include media-breakpoint-up(l) {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                margin: 0;
            }
            @include media-breakpoint-down(m) {
                flex-direction: column;
            }
            @include media-breakpoint-down(s) {
                display: block;
                margin: 0 auto;
            }
        }
        .c-profile-block__content-inner {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-right: auto;
        }
        .c-profile-block__before {
            @include type-scale(base);
            color: $dark;
            font-size: 1.125rem;
            font-weight: $font-weight--bold;
            margin-bottom: 0.5rem;
            text-transform: uppercase;
            width: 100%;
        }
        .c-profile-block__name {
            font-size: rem(26);
            font-weight: $font-weight--bold;
            line-height: 1.25;
            margin-bottom: 0.25rem;
            text-decoration: underline;

            &:hover {
                color: darken($primary, 10%);
            }
        }
        .c-profile-block__title {
            align-items: center;
            display: block;
            font-size: 1rem;
            font-style: italic;
            line-height: 1.25;
            /*@include media-breakpoint-up(m) {
                display: block;
            }*/
            @include media-breakpoint-down(s) {
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
            }
        }
        .c-profile-block__bio-button {
            margin-left: auto;
            min-width: 10rem;
            text-align: right;
            @include media-breakpoint-down(m) {
                margin-right: auto;
                text-align: center;
            }
        }
    }

    .c-attribution__author {
        border: 0.25rem solid $light;
        border-radius: rem(15);
        padding: 1.5rem 2rem;
        flex-wrap: wrap;

        .c-profile-block__extended-excerpt {
            margin-top: 1.5rem;

            @include media-breakpoint-only(m) {
                margin-top: 0.5rem;
            }
            @include media-breakpoint-down(s) {
                text-align: left;
            }
        }
        .c-profile-block__intro {
            line-height: 1.75;
            text-align: left;
            width: 100%;

            ul {
                margin-top: 1.5rem;
            }
            li {
                padding-left: 2rem;
                position: relative;
                margin-top: 1rem;

                &:before {
                    border-right: rem(2) solid $dark;
                    border-top: rem(2) solid $dark;
                    content: '';
                    display: block;
                    height: 0.4rem;
                    left: 0.35rem;
                    position: absolute;
                    top: 0.65rem;
                    transform: rotate(45deg);
                    width: 0.4rem;
                    z-index: 5;
                }
                &:after {
                    background: none;
                    border: rem(2) solid $dark;
                    border-radius: rem(3);
                    content: '';
                    display: block;
                    height: 1.25rem;
                    left: 0;
                    position: absolute;
                    top: 0.25rem;
                    width: 1.25rem;
                }
            }
            @include media-breakpoint-down(m) {
                display: inline-block;
            }
        }
        @include media-breakpoint-down(s) {
            padding: 1.25rem 2.5rem;
        }
    }

    .c-profile-block__content-email-button {
        padding-left: 1rem;

        @include media-breakpoint-down(m) {
            padding-left: 0;
        }
    }

    .c-profile-block__email {
        background: $light-blue;
        border-radius: rem(5);
        display: block;
        margin-left: auto;
        margin-top: 0.25rem;
        padding: 0.125rem 0.75rem;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
            text-decoration: underline;
        }
        &:before {
            color: $primary;
            margin-right: 0;
        }
        @include media-breakpoint-down(m) {
            display: inline-block;
            margin-top: .5rem;
        }
        @include media-breakpoint-down(s) {
            margin: 1rem auto 0 auto;
            padding: 0.4rem 1rem;
        }
    }

    .c-profile-block__email-label {
        margin-left: 0.5rem;
        text-transform: none;
    }

    .c-attribution__bio-link {
        display: inline-block;
        font-size: 1.125rem;
        font-weight: $font-weight--bold;
        margin-top: 1.5rem;
        padding-right: 1.75rem;
        position: relative;

        &:after {
            content: '\279E';
            position: absolute;
            right: 0;
        }
    }

    .c-attribution__button {
        flex: 0 0 auto;
    }

    .c-attribution__additional {
        flex-direction: column;

        @include media-breakpoint-up(m) {
            display: block;
            padding: 0 2.25rem;
        }

        @include media-breakpoint-down(m) {
            flex-direction: column;
        }

        & > div:last-child {
            margin-bottom: 0;
        }
    }
}

.c-attribution__hero--hub {
    margin-top: $spacer--3xl;
    margin-bottom: 0;
}

// Inline

.c-attribution--inline {
    border-top: rem(2) solid $light;
    border-bottom: rem(2) solid $light;
    padding: $spacer--l 0;
    font-size: rem(14);

    /*.c-page--one-column & {
        @include media-breakpoint-only(s) {
            padding-left: $spacer;
            padding-right: $spacer;
        }
    }*/

    .c-hero--backpage & {
        margin: $spacer--xl auto;
        padding-top: $spacer--xl;
        border-top: rem(2) solid $light;
    }

    .c-hero--single & {
        margin-top: $spacer--l;
    }

    .c-attribution__buttons .c-button {
        border-width: 0.1rem;
        font-weight: $font-weight--bold;
        padding: $spacer--2xs $spacer--xs;
    }

    .c-attribution__copy ul {
        line-height: 2;

        @include media-breakpoint-up(m) {
            li {
                &:before {
                    color: $primary;
                }
            }
        }
    }

    .c-popover {
        @include media-breakpoint-down(m) {
            position: static;
        }
    }

    .c-popover__content {
        @include media-breakpoint-down(m) {
            left: 1rem;
            right: 1rem;
        }
    }

    .c-popover__content:before {
        @include media-breakpoint-down(m) {
            display: none;
        }
    }

    .c-page__gutenberg & {
        max-width: 60rem;
    }
}

/*.c-page--one-column {
    .c-attribution__additional {
        .c-profile-block__content {
            flex-direction: column;
        }
        .c-profile-block__content-email-button {
            padding-left: 0;
        }
    }

    @include media-breakpoint-up(m) {
        .c-attribution--footer {
            display: flex;
            max-width: 60rem;
        }
    }
    @include media-breakpoint-down(m) {
        .c-attribution--footer {
            flex-direction: column; 

            .c-profile-block__email {
                margin-top: .5rem;
            }
        }
    }
}*/

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}