.c-experts {

    .c-profile-block {
        box-shadow: $box-shadow;
        margin-bottom: $spacer--xl;
        padding: $spacer;
        display: flex;
        flex-direction: column;
        text-align: center;

        @include media-breakpoint-up(m) {
            padding: $spacer--xl $spacer--l;
            display: flex;
            flex-direction: row;
            text-align: left;
        }
    }

    .c-profile-block__image {
        border-radius: 0;
        margin: 0 0 $spacer;

        @include media-breakpoint-up(m) {
            margin: 0 $spacer--l 0 0;
        }
    }

    .c-profile-block__content {
        color: $dark;
    }

    .c-profile-block__name {
        @include type-scale(m, 1.4);
    }

    .c-profile-block__title {
        @include type-scale(s, 1.4);
    }

    .c-profile-block__after {
        @include type-scale(s);
        margin-top: $spacer--s;
    }
}

.c-experts--footer {
    border-top: rem(1) solid $gray-100;
    margin-top: $spacer--xl;

    @include media-breakpoint-up(m) {
        margin-top: $spacer--4xl;
    }

    .h2 {
        text-align: center;
        &:after {
            display: none;
        }
    }
}

.c-page--one-column .c-content__after {

    > .c-experts--footer {
        max-width: 64rem;
    }

}

.c-hero__expert-modal-link {
    padding: 0 $spacer;
}

.c-experts__modal {
    text-align: center;
    border-radius: $spacer;
    
    &.c-modal { 
        padding-bottom: 0;
    }

    h3 {
        margin-bottom: $spacer--xl;
    }
}

.contributor-modal__contributor {
    display: block;
    border-bottom: thin solid $gray-300;
    max-width: 80%;
    margin: 0 auto;
    padding: 0.8rem 0;
}

.contributor-modal__read-more {
    @include type-scale(s);
    padding: 2rem;
    font-style: italic;
}
