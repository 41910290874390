.c-widget {
    @include get-styles-from-map($widget-styles);

    &:last-child {
        margin-bottom: 0;
    }
}

.c-widget__title {
    @include get-styles-from-map($widget-title-styles);
}





.c-widget--sticky {
    position: sticky;
    top: 2rem;
}
