@mixin site-header-height() {
    @include media-breakpoint-only(s) {
        height: $site-header-height--mobile;
    }
    @include media-breakpoint-only(m) {
        height: $site-header-height--tablet;
    }
    @include media-breakpoint-up(l) {
        height: $site-header-height--desktop;
    }
}

.c-site-header {
    color: $site-header-color;
    background-color: transparent;
    position: fixed;
    top: auto;
    width: 100%;
    min-width: 20rem;
    z-index: 1000;
    box-shadow: 0 rem(1) rem(1) 0 rgba(0, 0, 0, 0);
    transform: translateY(0%);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.3s transform ease-in-out;

    > .l-container {
        max-width: 80rem;
    }

    .has-scrolled &,
    &.is-active {
        background-color: $site-header-background-color;
        box-shadow: 0 rem(1) rem(1) 0 rgba(0, 0, 0, 0.3);
    }

    .hide-site-header & {
        transform: translateY(-110%);
    }

}



.c-site-header__content {
    @include site-header-height();
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}



.c-site-header__button {
    @include make-button-variant(
        transparent,
        transparent,
        $site-header-mobile-button-color,
        transparent,
        transparent,
        $site-header-mobile-button-hover-color
    );
    @include type-scale(l);
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 0;

    @include media-breakpoint-only(s) {
        width: $site-header-height--mobile;
        height: $site-header-height--mobile;
    }

    @include media-breakpoint-only(m) {
        width: $site-header-height--tablet;
        height: $site-header-height--tablet;
    }

    .c-hamburger {
        margin: 0 auto;
    }
}

.c-site-header__mobile {
    @include media-breakpoint-down(m) {
        margin-left: -($grid-gutter-width / 2);
        transition: 0.3s box-shadow ease-in-out;
        box-shadow: rem(1) 0 0 0 transparent;

        .has-scrolled & {
            box-shadow: rem(1) 0 0 0 color-level($site-header-background-color, $site-header-hover-level);
        }
    }

    @include media-breakpoint-up(l) {
        display: none;
    }
}

.c-site-header__phone {
    @include media-breakpoint-only(s) {
        .o-icon {
            @include type-scale(l, 1);
        }
    }
    @include media-breakpoint-only(m) {
        .o-icon {
            @include type-scale(xl, 1);
        }
    }
    @include media-breakpoint-down(m) {
        margin-right: -($grid-gutter-width / 2);
        transition: 0.3s box-shadow ease-in-out;
        box-shadow: rem(1) 0 0 0 transparent;

        .has-scrolled & {
            box-shadow: rem(-1) 0 0 0 color-level($site-header-background-color, $site-header-hover-level);
        }

        .copy { display: none; }
    }

    @include media-breakpoint-up(l) {
        padding-top: $spacer;
        padding-bottom: $spacer;
        color: $site-header-phone-color;

        &:hover {
            color: $site-header-phone-color;
        }

        .o-icon { display: none; }
        .copy {
            @include type-scale(base, 1);
            line-height: $site-header-height--desktop - ($spacer * 2);
            padding-left: $spacer--s;
            font-weight: $font-weight--bold;
        }
    }

    @include media-breakpoint-up(xl) {
        .copy {
            @include type-scale(l, 1);
        }
    }
}



.c-site-header__left {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    font-size: 0;

    a,
    a:hover {
        color: inherit;
        text-decoration: none;
    }
    @include media-breakpoint-only(s) {
        max-width: 12rem;
    }
    @include media-breakpoint-only(m) {
        max-width: 15rem;
    }
    @include media-breakpoint-down(m) {
        margin-right: $spacer--s;
        margin-left: $spacer--s;
    }
    @include media-breakpoint-up(l) {
        margin-right: $spacer--s;
        max-width: 28%;
    }
    @include media-breakpoint-up(xl) {
        margin-right: $grid-gutter-width;
        max-width: 19rem;
    }
}



.c-site-header__right {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    justify-content: flex-end;
}



.c-site-header__nav {
    @include media-breakpoint-down(m) {
        display: none;
    }
}
