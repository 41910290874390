.c-hero {
    padding-bottom: $spacer--6xl;
    margin-bottom: $spacer--5xl;
    background-color: $secondary;
    border-bottom: $spacer--xs solid $primary;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;

    @include media-breakpoint-only(s) {
        padding-top: $spacer--6xl + $site-header-height--mobile;
    }
    @include media-breakpoint-only(m) {
        padding-top: $spacer--6xl + $site-header-height--tablet;
    }
    @include media-breakpoint-up(l) {
        padding-top: $spacer--6xl + $site-header-height--desktop;
    }

    .c-hero__content {
        position: relative;
        z-index: 1;
    }
    .c-hero__main {
        background-color: #2e4f63;
        padding: 3rem 2rem;

        .c-hummingbird {
            border-left: 0.35rem solid #3cba8c;
        }
    }
    .c-hero__title {
        color: $white;
    }

    .c-hero__description {
        @include type-scale(base, 1.75);
        max-width: 40rem;
        color: $white;
    }

    .c-hero__disclaimer {
        @include type-scale(xs);
    }
    
    .c-hummingbird {
        max-width: $wysiwyg-max-width;
        color: $white;
    }

    &:after {
        content: '';
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($secondary, 0.8);
    }

    .c-cta--button {
        text-align: left;
        margin-top: 2rem;

        .c-button {
            font-size: rem(15);
        }
        
    }
}
.c-hero--backpage {
}


.c-hero--featured {
    text-align: center;
    margin-bottom: 0;

    @include media-breakpoint-only(s) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    @include media-breakpoint-only(m) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }

    @include media-breakpoint-up(l) {
        padding-top: 8rem;
        padding-bottom:  8rem;
    }

    .c-hero__content {
        max-width: 100%;
    }

    .c-hero__title {
        max-width: 40rem;
        margin-left: auto;
        margin-right: auto;
    }

    .c-hero__description {
        margin: $spacer--2xl auto;
    }

    .c-button {
        @include make-button--outline-white();
    }
}

.c-hero--brand {
    margin-bottom: 0;

    @include media-breakpoint-only(s) {
        padding-top: 6rem;
        padding-bottom: 3rem;
    }

    @include media-breakpoint-only(m) {
        padding-top: 7rem;
        padding-bottom: 4rem;
    }

    @include media-breakpoint-up(l) {
        padding-top: 11rem;
        padding-bottom:  11rem;
    }

    .c-hero__content {
        max-width: 48rem;
    }

    .c-hero__description {
        border-left: rem(5) solid $primary;
        padding-left: 1.25rem;
        max-width: none;
    }
}

.c-hero__before {
    padding-left: 0;
    padding-right: 0;
}


.c-hero--two-column {
    .c-cta {
        margin: 0;
        padding: 0;  
        max-width: none;
        align-items: flex-start;
    }
}