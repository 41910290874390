.c-dl {}

.c-dl__definition {
    padding-left: 0;
}

.c-dl__group {
    margin-bottom: $spacer;
    padding-bottom: $spacer;
    padding-top: $spacer--s;
    border-bottom: rem(1) solid $light;
}

.c-dl__title {
    font-family: $font-family--serif;
}
