.c-popover {
    position: relative;
    display: inline-block;
}
.c-popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -(rem(100));
    right: -(rem(100));
    top: 3rem;
    background-color: $light;
    padding: 1.5rem;
    box-shadow: $box-shadow;
    width: auto;
    max-width: 90vw;
}
.c-popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $light transparent;
    transition-duration: 0.3s;
    transition-property: transform;
}
.c-popover:hover .c-popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.c-popover__title {
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0;
}
.c-popover__jobtitle {
    margin-top: 0;
    font-style: italic;
    font-size: rem(15);
    line-height: 1.6;
}
.c-popover__excerpt {
    font-size: rem(15);
    line-height: 1.6;
}
.c-popover__content img {
    border-radius: 50%;
}
.c-popover__content .c-button--link {
    padding: 0;
}