.c-wysiwyg {
    h2,
    .h2 {
        &:after {
            margin-top: $spacer;
            width: $spacer--6xl;
            height: $spacer--3xs;
            background: $accent;
            content: '';
            display: block;
        }
    }
    h3,
    .h3 {
        padding-left: $spacer--l;
        border-left: $spacer--3xs solid $gray-200;
    }

    ol {
        list-style-type: none;
        counter-reset: section;
        margin: $spacer--m 0 $spacer--m $spacer--2xl;
        padding-left: 0;

        li {
            counter-increment: section;
            padding-left: $spacer--m;
            margin-bottom: $spacer--m;
            margin-bottom: $spacer--m;

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                color: $accent;
                content: counters(section, '');
                border: rem(2) solid $accent;
                border-radius: 50%;
                display: inline-block;
                float: left;
                width: $spacer--2xl;
                height: $spacer--2xl;
                line-height: rem(36);
                text-align: center;
                font-weight: $font-weight--bold;
                margin: rem(-4) 0 0 rem(-60);
            }
        }
    }
    ul {
        list-style-type: none;

        li {
            position: relative;

            &:before {
                @include type-scale(m, 1);
                content: '•';
                color: $accent;
                position: absolute;
                top: rem(4);
                left: -$spacer--m;
            }
        }
    }
    dl {}
    dt {}
    dd {}

}
