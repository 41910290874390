.c-factbox {
    margin-top: $spacer--xl;
    border-top: rem(1) solid color-step($accent, 100);
    padding: $spacer--l;
    border-bottom: rem(1) solid color-step($accent, 100);
    margin-bottom: $spacer--xl;
}

.c-factbox__title {
    @include type-scale(m);
    color: $secondary;
    font-family: $font-family--serif;
}

.c-factbox__copy {
    @include type-scale(base, 1.5);
    color: color-step($accent, 600);
}

.c-factbox__source {
    @include type-scale(xs);
}
